let available = true;
const $otpBlock = $('.js-otp-attempt');
const $otpInputBlock = $('.js-otp-attempt-input');
const $emailField = $('.js-email-field');
const $button = $('.js-get-two-factor-code');
const baseText = $button.text();

$emailField.on('blur', () => {
  $.get('/admin/two_factor_auth/check', { email: $emailField.val() }, ({ status }) => {
    if (status) {
      $otpBlock.removeClass('hide');
    } else {
      $otpBlock.addClass('hide');
    }
  });
});

$button.on('click', () => {
  if (available) {
    available = false;
    $button.text('Отправка...');
    $.ajax({
      url: '/admin/two_factor_auth/code',
      data: {
        email: $emailField.val(),
      },
      success({ message }) {
        $otpInputBlock.removeClass('hide');
        $('.js-code-info-header').text(message);
        $('.js-code-info').modal('show');
      },
      complete() {
        available = true;
        $button.text(baseText);
      },
    });
  }
});
