const complimentPagesBlockDisplayType = $('#compliment_pages_block_display_type');
const complimentPagesBlockMomentPlaylistIds = $('.compliment_pages_block_moment_playlist_ids');

complimentPagesBlockDisplayType.on('change', function displayTypeChange() {
  if (this.value !== 'moments_list') {
    complimentPagesBlockMomentPlaylistIds.css('display', 'none');
  } else {
    complimentPagesBlockMomentPlaylistIds.css('display', 'block');
  }
});

if (complimentPagesBlockDisplayType.val() !== 'moments_list') {
  complimentPagesBlockMomentPlaylistIds.css('display', 'none');
}
