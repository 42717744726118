<script>
import showNotification from '../../components/xz_notify_lib';
import EventBus from '../../event_bus';
import template from './template.slim';

export default {
  mixins: [template],
  props: {
    offers: Array,
    userId: String,
    userType: String,
    availablePaymentMethod: Boolean,
    userDeleted: Boolean,
    domainLocales: Array,
  },
  data() {
    return {
      showForm: false,
      withCharge: false,
      currentOfferId: null,
      currentType: this.userType,
      addPaidSubscriptionBtnShown: this.availablePaymentMethod,
      addFreeSubscriptionBtnShown: !this.userDeleted,
      selectedDuration: null,
      isSaving: false,
      currentDomainLocale: 'ru',
    };
  },
  mounted() {
    EventBus.$on('user-update', (data) => {
      this.currentType = data.user_type;
      this.duration = null;
    });
  },
  watch: {
    currentDomainLocale() {
      this.currentOfferId = null;
    },
  },
  methods: {
    openForm({ withCharge }) {
      this.withCharge = withCharge;
      this.showForm = true;
    },
    closeForm() {
      this.showForm = false;
    },
    addSubscription() {
      this.showForm = false
      this.isSaving = true;
      $.ajax({
        url: '/admin/subscriptions/',
        data: {
          subscription: {
            user_id: this.userId,
            offer_id: this.currentOfferId,
            duration: this.duration,
            state: 'normal_period',
            recurrent: false,
            with_charge: this.withCharge,
          },
        },
        type: 'POST',
        complete: () => {
          this.isSaving = false;
        },
        success: (data) => {
          this.currentOfferId = null;
          this.duration = null;
          EventBus.$emit('user-update', data);
        },
        error: (xhr) => {
          showNotification(xhr.responseJSON.errors, { type: 'error' });
        },
      });
    },
  },
  computed: {
    duration: {
      get() {
        if (this.selectedDuration) {
          return this.selectedDuration;
        }
        return this.currentOffer.duration;
      },
      set(value) {
        this.selectedDuration = value;
      },
    },
    currentOffer() {
      return this.offers.find((offer) => offer.id === this.currentOfferId);
    },
    offerOptions() {
      return this.offers.filter((o) => o.domain_locale === this.currentDomainLocale).map((o) => ({
        text: [o.title, `${o.duration} ${o.duration_unit}`, o.price, o.platform, `(${o.domain_locale})`].filter(Boolean).join('; '),
        value: o.id,
      }));
    },
    domainLocaleOptions() {
      return this.domainLocales.map((domainLocale) => ({
        text: domainLocale[0],
        value: domainLocale[1],
      }))
    },
    canSave() {
      return !this.isSaving && this.currentOffer;
    },
  },
};
</script>

<style scoped>
.close-form {
  position: absolute;
  top: 0px;
  right: 0px;
  cursor: pointer;
}
</style>
