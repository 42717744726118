import videojs from 'video.js';
import AnnotationComments from '@contently/videojs-annotation-comments';

import MomentChannel from '../../../channels/moment_channel';

// Play moment from start position
const playFromStart = (player, startOffset) => {
  player.currentTime(startOffset);
  player.play();
};

const convertFormattedTimeToSeconds = (string) => {
  const t = string.split(':').map(Number);
  return t[0] * 60 * 60 + t[1] * 60 + t[2];
};

// Seconds to UTC, i.e. 00:00
const formatSeconds = (seconds) => new Date(seconds * 1000).toISOString().substr(11, 8);

const initTimeInputs = (context, startOffset, endOffset) => {
  $('.moment-start-offset', context).val(startOffset);
  $('.moment-end-offset', context).val(endOffset);
  $('.moment-start-offset-formatted', context).val(formatSeconds(startOffset));
  $('.moment-end-offset-formatted', context).val(formatSeconds(endOffset));
};

// Find All VideoJS components
const findAllPlayers = () => $('video-js');

// Max VideoJS ID
const maxVideoId = () => {
  const array = [];

  findAllPlayers().each((i, el) => {
    const idAsArray = el.id.split('_');
    const element = parseInt(idAsArray[idAsArray.length - 1], 10);
    if (element) {
      array.push(element);
    }
  });
  return (array.length === 0) ? 0 : Math.max(...array);
};

// Init VideoJS player
function initPlayer(context, oldPlayer) {
  let startOffset = parseInt(Math.round($('.hidden .moment-start-offset', context).val()), 10);
  let endOffset = parseInt(Math.round($('.hidden .moment-end-offset', context).val()), 10);

  // Init Video Player
  const player = videojs(oldPlayer.id);

  player.controlBar = {
    children: [
      'playToggle',
      'volumeMenuButton',
      'durationDisplay',
      'timeDivider',
      'currentTimeDisplay',
      'progressControl',
      'fullscreenToggle',
    ],
  };

  $('.vjs-current-time', context).css('display', 'block');
  $('.vjs-remaining-time', context).css('display', 'none');

  player.ready(function playerReady() {
    player.on('timeupdate', () => {
      if (player.currentTime() >= endOffset - 0.2 && player.currentTime() <= endOffset + 1) {
        player.pause();
      }
    });
    player.on('loadedmetadata', () => {
      // this.el_ - variable from player
      // eslint-disable-next-line
      const form = $(this.el_).closest('.moment-fields');
      $('.moment-start-offset-formatted', form).removeAttr('disabled');
      $('.moment-end-offset-formatted', form).removeAttr('disabled');
      $('.play-between-button', form).removeAttr('disabled');
      $('.play-end-button', form).removeAttr('disabled');
    });
  });

  // Init Play Button
  const playButton = $('.play-between-button', context);
  playButton.removeAttr('disabled');
  playButton.click(() => playFromStart(player, startOffset));

  // Init Play End Button
  const playEndButton = $('.play-end-button', context);
  playEndButton.removeAttr('disabled');
  playEndButton.click(() => playFromStart(player, endOffset - 3));

  // Init Annotation Plugin
  const range = (endOffset) ? { start: startOffset, end: endOffset } : { start: 0, end: 1 };
  const annotationsObjects = [{
    id: 1,
    range,
    shape: {},
    comments: [{
      id: 1,
      meta: {},
      body: 'Moment',
    }],
  }];

  const pluginOptions = {
    annotationsObjects,
    meta: { user_id: null, user_name: null, state: 'default' },
    bindArrowKeys: true,
    showControls: true,
    showCommentList: false,
    showFullScreen: true,
    showMarkerShapeAndTooltips: true,
    internalCommenting: false,
    startInAnnotationMode: true,
  };

  const plugin = player.annotationComments(pluginOptions);

  // Init Cancel Button
  const resetButton = $('.reset_range_button', context);
  resetButton.click(function resetButtonClick() {
    plugin.fire('openAnnotation', { id: 1 });
    plugin.fire('cancelAddingAnnotation');
    $(this).attr('disabled', true);
  });

  $('.moment-offset', context).on('change', () => {
    const start = convertFormattedTimeToSeconds($('.moment-start-offset-formatted', context).val());
    const end = convertFormattedTimeToSeconds($('.moment-end-offset-formatted', context).val());
    plugin.fire('addingAnnotationDataChanged', { range: { start, end } });
    plugin.controls.marker.range = { start, end };
    plugin.controls.marker.teardown();
    plugin.controls.marker.render();
  });

  // Init Plugin Callbacks
  plugin.onReady(() => {
    plugin.registerListener('addingAnnotationDataChanged', (event) => {
      const newRange = event.detail.range;

      startOffset = parseInt(Math.round(newRange.start), 10);
      endOffset = parseInt(Math.round(newRange.end), 10);

      initTimeInputs(context, startOffset, endOffset);
    });

    plugin.registerListener('annotationOpened', (event) => {
      startOffset = parseInt(Math.round(event.detail.annotation.range.start), 10);
      endOffset = parseInt(Math.round(event.detail.annotation.range.end), 10);

      initTimeInputs(context, startOffset, endOffset);
    });
    plugin.registerListener('enteredAddingAnnotation', () => {
      $('.reset_range_button', context).removeAttr('disabled');
    });
  });
}

function initMomentsInterface() {
  const momentsParentContentId = $('#moments_parent').attr('content_id');
  MomentChannel(momentsParentContentId);

  const spinnerHtml = '<div class="ui active centered inline loader"></div>';

  $('.cut_moment').click(function cutMomentClick() {
    const cutMomentId = $(this).attr('moment_id');
    const cutMomentContentId = $(this).attr('content_id');
    $(this).attr('disabled', true);

    $(`#preview_${cutMomentId}`).html(spinnerHtml);
    $(`#code_${cutMomentId}`).html(spinnerHtml);
    $(`#files_${cutMomentId}`).html(spinnerHtml);
    $(`#status_${cutMomentId}`).html(spinnerHtml);
    $(`#hls_${cutMomentId}`).html(spinnerHtml);

    $.post(`/admin/contents/${cutMomentContentId}/moments/${cutMomentId}/crop`);
  });

  $('.cut_moment_as_chunk_list').click(function cutMomentAsChunkListClick() {
    const cutAsChunkMomentId = $(this).attr('moment_id');
    const cutAsChunkContentId = $(this).attr('content_id');
    $(this).attr('disabled', true);

    $(`#preview_${cutAsChunkMomentId}`).html(spinnerHtml);
    $(`#code_${cutAsChunkMomentId}`).html(spinnerHtml);
    $(`#status_${cutAsChunkMomentId}`).html(spinnerHtml);
    $(`#hls_${cutAsChunkMomentId}`).html(spinnerHtml);

    $.post(`/admin/contents/${cutAsChunkContentId}/moments/${cutAsChunkMomentId}/crop_as_short_chunk_list`);
  });

  videojs.registerPlugin('annotationComments', AnnotationComments(videojs));

  findAllPlayers().each((i, el) => {
    const form = $(el).closest('.moment-fields');
    initPlayer(form, el);
  });

  // Cocoon Add and Remove
  $('.moments-fields')
    .on('cocoon:after-insert', (e, form) => {
      const player = $('video-js', form)[0];
      if (player) {
        $(player).attr('id', `video_moment_${maxVideoId() + 1}`);
        initPlayer(form, (player));
      }
    })
    .on('cocoon:before-remove', (e, form) => {
      let player = $('video-js', form)[0];
      if (player) {
        player = videojs(player.id);
        player.dispose();
      }
    });

  $('.publish_moment_form').click(function publishMomentFormClick() {
    $(this).submit();
  });
}

initMomentsInterface();

$(document).on('tab:loaded', '.js-episodes-tab', () => initMomentsInterface());
