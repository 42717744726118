import showNotification from '../xz_notify_lib';

const contentSync = $('.js-content-sync');

// TODO: mv local to module
const locale = $('html').attr('lang');

contentSync.on('click', (e) => {
  e.preventDefault();

  if (window.confirm('Вы уверены?')) {
    $.ajax({
      url: '/admin/contents/sync',
      type: 'POST',
      data: {
        content_id: contentSync.data('content-id'),
        force: contentSync.data('force'),
      },
      success() {
        document.location.reload();
      },
      error() {
        const message = locale === 'ru' ? 'Не удалось синхронизировать данные' : 'Failed to sync content';
        showNotification(message, { type: 'error' });
      },
    });
  }
});

const $contentLicenseSyncBtn = $('.js-content-license-sync-btn');
$contentLicenseSyncBtn.on('click', (e) => {
  e.preventDefault();

  $.ajax({
    url: '/admin/contents/sync_license',
    type: 'POST',
    data: {
      content_id: $contentLicenseSyncBtn.data('content-id'),
    },
    success() {
      document.location.reload();
    },
    error({ responseJSON: { errors, error } }) {
      showNotification(errors || error, { type: 'error' });
    },
  });
});
