/* global GridStackUI */

import './switch_locale';

const insertContentToGrids = () => {
  $('.grid-stack .grid-stack-item .grid-stack-item-content').css({
    'background-image': '',
    'background-color': '',
  });
  $('#collection_items .nested-fields').each((index, collectionItemField) => {
    let id;
    const self = $(collectionItemField);
    if (self.find('input[name$="[_destroy]"]').val() === 'false') {
      const position = self.find('.position-field').val();
      const itemType = self.find('.collection-item-type-select').find('select').val();
      if (itemType === 'content' || itemType === 'continue') {
        id = self.find('.collection-content-select').find('select').val();
        $.getJSON(`/content/descriptions/${id}`, (data) => {
          $('.grid-stack').each((i, el, d = data.content) => {
            const content = $(el)
              .find(`.grid-stack-item[data-position='${position}']`)
              .find('.grid-stack-item-content');
            return content.css('background-image', `url("${d.poster}")`);
          });
        });
      }
      if (itemType === 'subcollection') {
        id = self.find('.collection-subcollection-select').find('select').val();
        $.getJSON(`/content/collections/${id}`, (data) => {
          $('.grid-stack').each((i, el, d = data.collection) => {
            const content = $(el)
              .find(`.grid-stack-item[data-position='${position}']`)
              .find('.grid-stack-item-content');
            return content.css('background-image', `url("${d.poster}")`);
          });
        });
      }
      if (itemType === 'random') {
        setTimeout((() => $('.grid-stack').each((i, el) => {
          const content = $(el).find(`.grid-stack-item[data-position='${position}']`).find('.grid-stack-item-content');
          // TODO: random_movie.png not exists
          return content.css({
            'background-image': "url('assets/random_movie.png')",
            'background-color': 'purple',
          });
        })), 1);
      }
      if (itemType === 'genres') {
        setTimeout((() => $('.grid-stack').each((i, el) => {
          const content = $(el).find(`.grid-stack-item[data-position='${position}']`).find('.grid-stack-item-content');
          // TODO: movies_by_genres.png not exists
          return content.css({
            'background-image': "url('assets/movies_by_genres.png')",
            'background-color': 'yellow',
          });
        })), 1);
      }
    }
  });
};
const switchContentInCollectionItems = (switcher) => {
  const tr = switcher.closest('.nested-fields');
  const fields = tr.find('.collection-item-select-field');
  const field = tr.find(`.collection-${switcher.val()}-select`);
  fields.addClass('hide');
  field.removeClass('hide');
  tr.find('td.content-link').contents().toggle(['content', 'continue'].includes(switcher.val()));
  const trailerAutoplayContainer = tr.find('td.trailer-autoplay');
  trailerAutoplayContainer
    .contents()
    .toggle(
      ['content', 'continue'].includes(switcher.val())
      && trailerAutoplayContainer.attr('data-trailer-presence') === 'true',
    );
  insertContentToGrids();
};
const updateContentInGrids = (grids) => {
  $('#grids').html('');
  const compactGrids = grids.filter((gridId) => gridId);
  const xhrs = compactGrids.map((gridId) => $.getJSON(`/dictionaries/grids/${gridId}`, (data) => {
    let gridHtml = `
          <div class="ui divider"></div>
          <h3 class="ui header">${data.title}</h3>
          <div id="grid_${gridId}" class="grid-stack grid-stack-static" data-gs-width="${data.width}">
        `;
    data.slots.forEach((slot) => {
      gridHtml += `
            <div class="grid-stack-item nested-fields"
             data-id="${slot.id}"
             data-position="${parseInt(slot.position, 10) + 1}"
             data-gs-width="${slot.width}"
             data-gs-height="${slot.height}"
             data-gs-x="${slot.x}"
             data-gs-y="${slot.y}">
              <div class="grid-stack-item-content">
                <div class="title"></div>
                <div class="grid-stack-item-info">
                  <div class="position">${parseInt(slot.position, 10) + 1}</div>
                  <div class="size">
                    <span class="width">${slot.width}</span>
                    x
                    <span class="height">${slot.height}</span>
                  </div>
                </div>
              </div>
            </div>
          `;
    });
    gridHtml += '</div>';
    $('#grids').append(gridHtml);
    const gridEl = $(`#grid_${gridId}`);
    gridEl.gridstack({ width: data.width.width });
    gridEl.data('gridstack').setStatic(true);
    if (xhrs.every((xhr) => !xhr || xhr.readyState === 4)) {
      insertContentToGrids();
    }
  }));
};
const initializePage = () => {
  let column;
  let j;
  let len;

  // Semantic UI
  $('.ui.dropdown:not(.vue)').dropdown('set selected');
  $('.accordion').accordion();
  $('.message .close').on('click', function messageCloseClick() {
    $(this).closest('.message').transition('fade');
  });
  $('.open-popup').on('click', () => $('.ui.modal').modal('show'));
  $('.js-ban-btn').on('click', () => $('.js-ban-popup').modal('show'));
  $('.activating.element').popup();
  $('.ui.success.message').delay(1800).fadeOut('fast', 'swing');
  $('.ui.error.message').delay(6000).fadeOut('fast', 'swing');
  $('.file-title').popup({
    inline: true,
    hoverable: true,
    lastResort: true,
    delay: {
      show: 300,
      hide: 800,
    },
  });
  $('.ui.progress').progress({
    total: 100,
  });
  const onGridChange = () => {
    updateContentInGrids($('.grid-dropdown').map((i, el) => $(el).dropdown('get value')).toArray());
  };
  onGridChange();
  setTimeout(() => $('.grid-dropdown').dropdown('setting', 'onChange', onGridChange), 100);
  $('.ui.sticky.tv-program-filters').sticky({
    context: '#tv-program-table',
    observeChanges: true,
    onStick() {
      return $('#scroll-top-button').toggle();
    },
    onUnstick() {
      return $('#scroll-top-button').toggle();
    },
  });

  // Other
  $.bindEvents();
  // $.attach_errors()
  $.each($('.episode-fields'), (i, el) => {
    $.check_to_hide_or_show_add_link($(el));
  });
  $.initialize_sortable_collection_items();
  $.initFlags();
  const ref = $('.home-column');
  // Remove empty columns on main page
  const results = [];
  for (j = 0, len = ref.length; j < len; j += 1) {
    column = ref[j];
    if (column.innerHTML === '<ul></ul>') {
      results.push(column.remove());
    } else {
      results.push(undefined);
    }
  }
  return results;
};
// Global functions:
// -----------------
$.update_grid = (grid) => {
  const items = GridStackUI.Utils.sort(grid.grid.nodes);
  let i = 0;
  const results = [];
  while (i < items.length) {
    const { el } = items[i];
    const position = el.find('.slot-position');
    const width = el.find('.slot-width');
    const height = el.find('.slot-height');
    const x = el.find('.slot-x');
    const y = el.find('.slot-y');
    const info = el.find('.grid-stack-item-info');
    const positionDisplay = info.find('.position');
    const widthDisplay = info.find('.width');
    const heightDisplay = info.find('.height');
    position.val(i);
    width.val(items[i].width);
    height.val(items[i].height);
    x.val(items[i].x);
    y.val(items[i].y);
    positionDisplay.text(i + 1);
    widthDisplay.text(items[i].width);
    heightDisplay.text(items[i].height);
    results.push(i);
    i += 1;
  }
  return results;
};
$.check_to_hide_or_show_add_link = (episode) => {
  episode
    .find('.poster-fields')
    .find('.links a')[episode.find('.poster-fields:visible').length >= 1 ? 'hide' : 'show']();
};
$.initFlags = () => {
  $('.countries-select .item:not(:has("i.flag")), .countries-select a.ui.label:not(:has("i.flag"))').each((i, el) => {
    $(el).prepend(`<i class='flag ${$(el).data('value').toLowerCase()}'></i>`);
  });
};
$.reorder_collection_items = () => {
  $('#collection_items').find('tbody').find('tr:visible').each((index, item) => {
    $(item).find('input.position-field').val(index + 1);
  });
  insertContentToGrids();
};
$.initialize_sortable_collection_items = () => {
  $('#collection_items').find('tbody').sortable({
    handle: '.sidebar.handler',
    update() {
      return $.reorder_collection_items();
    },
  });
};
// Events:
$.bindEvents = () => {
  // Unbind all events if there are any previously attached:
  // -------------------------------------------------------
  // -------- commented this, causing troubles with binding events in other js -------
  // $(window).off()
  // $(document).off()
  // Window events:
  // --------------
  // Update page for turbolinks:
  $(window).bind('page:change', initializePage);
  // Switch locale fields
  $(document)
    .on('cocoon:after-insert', (e, addedObject) => {
      $.initFlags();
      $.check_to_hide_or_show_add_link(addedObject.closest('.episode-fields'));
    })
    .on('cocoon:after-remove', (e, removedObject) => {
      $.check_to_hide_or_show_add_link(removedObject.closest('.episode-fields'));
    });

  const grid = $('#grid');
  const gridStack = $('.grid-stack');
  grid
    .on('cocoon:after-insert', (e, addedObject) => {
      const dataGridStack = gridStack.data('gridstack');
      dataGridStack.addWidget(addedObject);
      $.update_grid(dataGridStack);
    })
    .on('cocoon:after-remove', (e, removedObject) => {
      const dataGridStack = gridStack.data('gridstack');
      dataGridStack.removeWidget(removedObject, false);
      $.update_grid(dataGridStack);
    });
  gridStack.on('change', () => $.update_grid(gridStack.data('gridstack')));
  $(document)
    .on('change', '#dictionaries_grid_width', function dictionariesGridWidthChange() {
      $('.grid-stack').data('gridstack').setGridWidth($(this).val(), true);
      grid.attr('data-gs-width', $(this).val());
    })
    .on('change', '.collection-item-type-select select', function collectionTypeChange() {
      switchContentInCollectionItems($(this));
    });
  $('#collection_items')
    .on('cocoon:after-insert', (e, addedObject) => {
      const select = addedObject.find('.collection-item-type-select');
      $.reorder_collection_items();
      switchContentInCollectionItems(select);
      $.initialize_sortable_collection_items();
      insertContentToGrids();
    })
    .on('cocoon:after-remove', () => {
      $.reorder_collection_items();
      insertContentToGrids();
    });
  $(document)
    .on('content:selected', insertContentToGrids)
    .on(
      'click',
      '.collection-content-select .random-button, .collection-continue-select .random-button',
      function randomButtonClick() {
        const searchDropdown = $(this).closest('tr.nested-fields').find('.collections-search');
        const dropdown = $(this)
          .closest('.nested-fields')
          .find('.collection-content-select, .collection-continue-select')
          .find('.dropdown');
        const select = dropdown.find('select');
        const loader = $(this).find('.loader');
        const icon = $(this).find('i.random.icon');
        icon.hide();
        loader.addClass('active');
        $.getJSON('/content/descriptions/random', (data) => {
          const { id } = data.content;
          const { title } = data.content;
          select.find('option:selected').remove();
          select.append(`<option selected="selected" value="${id}">${title}</option>`);
          const { url } = data.content;
          const linkContainer = searchDropdown.closest('tr.nested-fields').find('td.content-link');
          const text = $('html').attr('lang') === 'ru' ? 'Перейти к контенту' : 'Go to the content';
          const html = `<a target='_blank' href='${url}'>${text}</a>`;
          linkContainer.html(html);
          dropdown.dropdown('refresh');
          setTimeout((() => dropdown.dropdown('set selected', id)), 1);
          loader.removeClass('active');
          icon.show();
          insertContentToGrids();
        });
      },
    )
    .on('click', '.collection-subcollection-select .random-button', function randomSubCollectionClick() {
      const dropdown = $(this).closest('.nested-fields').find('.collection-subcollection-select').find('.dropdown');
      const select = dropdown.find('select');
      const { length } = dropdown.find('.menu').find('.item');
      const random = Math.floor(Math.random() * length);
      select.find(`:nth-child(${random})`).prop('selected', true);
      const id = select.val();
      dropdown.dropdown('refresh');
      setTimeout((() => dropdown.dropdown('set selected', id)), 1);
      insertContentToGrids();
    });

  $('.collection-type.menu .item').tab({
    onVisible() {
      return $('#content_collection_collection_type').val($(this).data('tab'));
    },
  });

  $(document)
    .on('click', '#add-file-uploader', () => {
      $('#file-uploaders-holder').append($('#file-uploader-template').html());
      $('.ui.dropdown').dropdown('set selected');
    });
};

initializePage();
