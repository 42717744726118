<template>
  <file-uploader
    :container-id="containerId"
    :content-id="contentId"
    :file-added-url="fileAddedUrl"
    :file-added-data="fileAddedData"
    :chunk-uploaded-url="chunkUploadedUrl"
    :file-success-url="fileSuccessUrl"
    :finish-upload-url="finishUploadUrl"
    :show-pipeline="true"
  />
</template>

<script>
import fileUploader from '../file_uploader/index.vue';

export default {
  components: {
    fileUploader,
  },
  props: {
    containerId: String,
    contentId: String,
  },
  data() {
    return {
      fileAddedUrl: () => '/admin/uploaders.json',
      fileAddedData: {},
      chunkUploadedUrl: (file) => `/admin/uploaders/${file.playlistId}/chunks_data.json`,
      fileSuccessUrl: (file) => `/admin/uploaders/${file.playlistId}/chunks_data.json`,
      finishUploadUrl: (file) => `/admin/uploaders/${file.playlistId}/finish.json`,
    };
  },
};
</script>
