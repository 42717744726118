import showNotification from '../xz_notify_lib';

$(document)
  .on('ajax:success', '.form', ({ detail: [data] }) => {
    if (data.redirect != null) {
      window.location.replace(data.redirect);
    }
  })
  .on('ajax:error', '.form', ({ target, detail: [data, , { status }] }) => {
    const $form = $(target);
    if (status === 422) {
      const $list = $('<div>').addClass('ui error list');
      if (data.errors != null) {
        data.errors.forEach((error) => {
          $list.append($('<div>').addClass('item').text(error));
          showNotification(error, { type: 'error', position: 'se' });
        });
        let $errorsContainer = $form.find('.errors-container');
        $errorsContainer.empty();
        if ($errorsContainer.length === 0) {
          $errorsContainer = $('<div>').addClass('errors-container').prependTo($form);
        }
        $errorsContainer.append($list);
      }
    }
  });
