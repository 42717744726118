import showNotification from '../xz_notify_lib';

const $modelCount = $('#model_count');

$(document)
  .on('ajax:success', '.js-delete-row', function deleteRowAjaxSuccess() {
    showNotification('Запись успешно удалена', { type: 'success' });
    $(this).closest('tr').remove();
    if ($modelCount.length) {
      $modelCount.html(parseInt($modelCount.text(), 10) - 1);
    }
  })
  .on('ajax:error', '.js-delete-row', ({ detail: [{ errors }, , { status }] }) => {
    if (status === 422 && (errors != null)) {
      showNotification(errors.join('; '), { type: 'error', expire: 30000 });
    }
  });
