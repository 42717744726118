const reorderDefaultMomentItems = () => {
  $('#default-playlist-items tbody').find('tr:visible').each((index, item) => {
    const container = $('.position-field', item);
    container.find('span').html(index + 1);
    container.find('input').val(index + 1);
  });
};
const initSortableDefaultMomentItems = () => {
  $('#default-playlist-items tbody').sortable({
    opacity: 0.5,
    axis: 'y',
    handle: '.handler',
    tolerance: 'pointer',
    cursor: 'move',
    update() {
      reorderDefaultMomentItems();
    },
  });
};

if ($('#default-playlist-items').length) {
  initSortableDefaultMomentItems();
}
