export default {
  en: {
    no_data: 'No data',
    save: 'Save',
    more: 'More',
    show_less: 'Show less',
    are_you_sure: 'Are you sure?',
    change: 'Change',
    users: {
      user: 'User',
      basic: 'Basic',
      pin_changed: 'PIN changed',
      pin_hint: 'Input 4 digits',
      email_changed: 'Email changed',
      name_changed: 'Name changed',
      edit_comment: 'Edit comment',
      add_comment: 'Add comment',
      add_partner_subscription: 'Add Partner Subscription',
      add_paid_subscription: 'Add paid subscription',
      add_free_subscription: 'Add free subscription',
      add_free_subscription_info: 'Will not charge money from user',
      add_paid_subscription_warning: 'Will charge money for paid subscription from user!',
      destroy: 'Destroy Tester User',
      choose_partner: 'Choose a Partner',
      choose_offer: 'Choose an Offer',
      choose_domain_locale: 'Choose an domain locale',
      trial_days_left: 'trial {days} days left',
      push_notifications: 'Push notifications',
      empty_password: 'Password is empty',
      send_reset_password_token: 'Send email to reset password',
      email_blank: 'Email is blank',
      vipplay: 'from Vipplay',
      attributes: {
        ban_reason: 'Ban reason',
        email: 'Email',
        comments: 'Comments',
        trial_duration: 'Trial duration',
        name: 'Name',
        pin: {
          true: 'Change PIN',
          false: 'Create PIN',
          nullify: 'Nullify PIN',
        },
        with_pin: {
          true: 'PIN is set. You can change it',
          false: 'PIN is NOT set. You can create it',
        },
        user_type: 'User Type',
        partners: 'Partners',
        placeholders: {
          email: 'john.smith@example.com',
          name: 'John Smith',
          comments: 'Add comment for the user',
        },
        tester_settings: 'Tester settings',
        send_sms: 'Send sms',
        test_payment: 'Test payment',
      },
      user_types: {
        regular: 'Regular',
        tester: 'Tester',
        service: 'Service',
        partner: 'Partner',
      },
      devices: {
        title: 'Last Devices',
      },
      segments: {
        title: 'Interests',
      },
      topics: {
        title: 'Channel',
      },
    },
    user_parameters: {
      title: 'User Parameters',
    },
    payment_methods: {
      title: 'Cards',
      no_card: 'No cards attached',
    },
    payture_credentials: {
      title: 'Payture credentials',
    },
    auth_providers: {
      title: 'Auth Providers',
    },
    subscriptions: {
      title: 'Subscriptions',
      refund: 'Refund Subscription',
      cancel: 'Cancel Subscription',
      activate_auto_renew: 'Activate Auto Renew',
      deactivate_auto_renew: 'Deactivate Auto Renew',
      destroy: 'Delete Subscription',
      restore: 'Restore Mobile Subscription',
      ends_at_changed: 'Changed',
      no_cancel_reason: 'Cancel reason is not specified',
      refunded: 'The subscription was refunded, the subscription was canceled',
      canceled: 'The subscription have been successfully canceled',
      attributes: {
        purchase_price: 'Purchase price',
        platform: 'Platform',
        created_at: 'Purchase date',
        period_of_validity: 'Period of validity',
        promo_code: 'Promo-code',
        canceled_at: 'Canceled at',
        refund_reason: 'Refund reason',
        cancel_reason: 'Cancel reason',
        full_refund: 'Full refund',
        duration: "The term of the subscription offer's duration units",
        ends_at: 'Valid to...',
        already_restored_by: 'Also restored by',
        placeholders: {
          refund_reason: 'Specify the refund reason',
          cancel_reason: 'Specify the cancel reason',
        },
      },
    },
  },
  ru: {
    no_data: 'Нет данных',
    save: 'Сохранить',
    more: 'Больше',
    show_less: 'Скрыть',
    are_you_sure: 'Вы уверены?',
    change: 'Изменить',
    close: 'Закрыть',
    users: {
      user: 'Пользователь',
      basic: 'Основное',
      pin_changed: 'ПИН-код изменен',
      pin_hint: 'Введите 4 цифры',
      email_changed: 'Email изменен',
      name_changed: 'Имя изменено',
      edit_comment: 'Изменить комментарий',
      add_comment: 'Добавить комментарий',
      add_partner_subscription: 'Добавить партнерскую подписку',
      add_paid_subscription: 'Добавить платную подписку',
      add_free_subscription: 'Добавить бесплатную подписку',
      add_free_subscription_info: 'За добавление подписки с пользователя не спишутся деньги.',
      add_paid_subscription_warning: 'За добавление подписки с пользователя спишутся деньги!',
      destroy: 'Удалить тестового пользователя',
      choose_partner: 'Выберите партнера',
      choose_offer: 'Выберите подписку',
      choose_domain_locale: 'Выберите локаль домена',
      push_notifications: 'Пуш-уведомления',
      ad_mailing_yes: 'Рекламная рассылка включена',
      ad_mailing_no: 'Рекламная рассылка отключена',
      empty_password: 'Пароль не задан',
      send_reset_password_token: 'Отправить письмо для сброса пароля',
      email_blank: 'Email не задан',
      vipplay: 'из Vipplay',
      attributes: {
        ban_reason: 'Причина бана',
        email: 'Email',
        comments: 'Комментарии',
        trial_duration: 'Длительность триала',
        name: 'Имя',
        pin: {
          true: 'Сменить ПИН-код',
          false: 'Создать ПИН-код',
          nullify: 'Обнулить ПИН-код',
        },
        with_pin: {
          true: 'ПИН-код задан. Можно его сменить',
          false: 'ПИН-код НЕ задан. Можно его создать',
        },
        user_type: 'Тип пользователя',
        partners: 'Партнеры',
        placeholders: {
          email: 'alex.smirnov@example.com',
          name: 'Александр Смирнов',
          comments: 'Добавьте пользователю комментарий',
        },
        tester_settings: 'Настройки тестовых пользователей',
        send_sms: 'Отправлять смс',
        test_payment: 'Тестовая оплата',
      },
      user_types: {
        regular: 'Обычный',
        tester: 'Тестовый',
        service: 'Служебный',
        partner: 'Партнерский',
      },
      devices: {
        title: 'Последние устройства',
      },
      segments: {
        title: 'Интересы',
      },
      topics: {
        title: 'Каналы',
      },
      notifications: {
        sber_info: 'Сбер уведомление',
      },
    },
    user_parameters: {
      title: 'Параметры пользователя',
    },
    payment_methods: {
      title: 'Платежные карты',
      no_card: 'Карта не привязана',
      sync_card: 'Синхронизировать карты',
      synced: 'Карты успешно синхронизированы',
    },
    payture_credentials: {
      title: 'Payture реквизиты',
    },
    auth_providers: {
      title: 'Аутентификация через...',
    },
    subscriptions: {
      title: 'Подписки',
      refund: 'Отменить подписку и вернуть деньги',
      cancel: 'Отменить подписку',
      history_button: 'История изменений',
      activate_auto_renew: 'Включить автопродление',
      deactivate_auto_renew: 'Отключить автопродление',
      enable_sber_auto_prolong_info: 'Для сбер подписок нет возможности включить автопродление',
      destroy: 'Удалить подписку',
      restore: 'Восстановить мобильную подписку',
      ends_at_changed: 'Изменено',
      no_cancel_reason: 'Причина отмены не указана',
      refunded: 'Стоимость подписки была возвращена пользователю, подписка отменена',
      canceled: 'Подписка была успешно отменена',
      next_renewal_product_warning: 'При следующем расчетном периоде подписка будет переключена на другой план',
      vipplay_canceled: 'Необходимо отменить подписку в VIPPLAY',
      attributes: {
        purchase_price: 'Цена покупки',
        platform: 'Платформа',
        created_at: 'Дата покупки',
        period_of_validity: 'Cрок действия',
        promo_code: 'Промо-код',
        canceled_at: 'Отменена',
        refund_reason: 'Причина возврата',
        cancel_reason: 'Причина отмены',
        full_refund: 'Полный возврат',
        duration: 'Срок действия подписки в единицах подписки',
        ends_at: 'Действует до...',
        already_restored_by: 'Восстановлена пользователем',
        placeholders: {
          refund_reason: 'Укажите причину возврата',
          cancel_reason: 'Укажите причину отмены',
        },
      },
      show_deleted: 'Показать удалённые',
      show_initial: 'Показать initial',
      hide_initial: 'Скрыть initial',
      history: {
        created_at: 'Дата изменения',
        event: 'Событие',
        changes: {
          title: 'Изменения',
          name: 'Название',
          before: 'До',
          after: 'После',
          not_exists: 'Нет данных об изменениях',
        },
        whodunnit: 'Кто вносил правки',
      },
    },
    analytics: {
      title: 'Аналитика',
      sum: 'Сумма за период: {sum} руб.',
    },
    timeline: {
      bonus_period: 'Бонусный период',
      trial_period: 'Триальный период',
      normal_period: 'Нормальный период',
      current_subscription: 'Текущая подписка',
    },
  },
};
