import consumer from './consumer';
import showNotification from '../src/components/xz_notify_lib';

const momentParent = document.querySelector('#moments_parent');

if (momentParent) {
  const contentId = momentParent.attributes.content_id.value;

  consumer.subscriptions.create({ channel: 'MomentNotifyChannel', room: contentId }, {
    received(data) {
      showNotification(`<b>${data.message.title}</b> <br /> ${data.message.text}`, {}, true);
    },
  });
}
