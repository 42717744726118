import consumer from './consumer';
import showNotification from '../src/components/xz_notify_lib';

if (window.currentAdmin?.id) {
  consumer.subscriptions.create({ channel: 'NotificationChannel', room: window.currentAdmin.id }, {
    received({ message }) {
      showNotification(message, { type: 'info' });
    },
  });
}
