$(document).on('click', '.js-update-translit', function updateTranslitClick() {
  const inputName = 'title_ru';
  const $updateTranslitBtn = $(this);
  const id = $updateTranslitBtn.data('id');
  const className = $updateTranslitBtn.data('class-name');
  const $fieldsContainer = $(this).parent().parent();
  const titleRuInputValue = $fieldsContainer.find(`[name*=${inputName}]`).val();

  $.ajax({
    type: 'POST',
    url: `/admin/update_translit?id=${id}&class_name=${className}&title_value=${titleRuInputValue}`,
    success: ({ translit }) => {
      if ($(this).closest('div').find('input').length) {
        $(this).closest('div').find('input').val(translit);
      } else {
        $(this).closest('div').find('span').text(translit);
      }
    },
  });
});
