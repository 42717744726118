import moment from 'moment';

class Version {
  constructor(attributes) {
    Object.assign(this, attributes);
    this.created_at = moment(this.created_at);
  }
}

export default Version;
