import moment from 'moment';
import Subscription from './subscription';

class User {
  constructor(attributes) {
    Object.assign(this, attributes);
    this.created_at = moment(this.created_at);
    this.deleted_at = moment(this.deleted_at);

    this.errors = {};

    this.subscriptions = this.subscriptions.map((subscription) => new Subscription(subscription));
  }

  get havePaymentMethod() {
    return this.payment_methods.length > 0;
  }
}

export default User;
