<template>
  <uploader
      :options="options"
      @file-added='onFileAdded'
      @file-success='onFileSuccess'
      @file-error='onFileError'
      :auto-start='false'
  >
    <uploader-unsupport></uploader-unsupport>
    <uploader-drop>
      <p>Перетащите сюда файл/файлы для загрузки или</p>
      <uploader-btn>Выберите на компьютере</uploader-btn>
    </uploader-drop>
    <uploader-list>
      <template v-slot='listScope'>
        <sui-table celled>
          <sui-table-body>
            <uploaded-files
              :file="file"
              :list="true"
              v-for="file in listScope.fileList"
              :key="file.id"
              :show-pipeline="showPipeline"
            />
          </sui-table-body>
        </sui-table>
      </template>
    </uploader-list>
  </uploader>
</template>

<script>
/* eslint-disable no-console, no-param-reassign */

import sha256 from 'fast-sha256';
import UploadedFiles from './uploaded_files.vue';

export default {
  components: { UploadedFiles },
  props: {
    containerId: String,
    fileAddedUrl: Function,
    fileAddedData: Object,
    chunkUploadedUrl: Function,
    fileSuccessUrl: Function,
    finishUploadUrl: Function,
    showPipeline: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      options: {
        // eslint-disable-next-line
        target: (file, chunk) => `https://filespot.platformcraft.ru/2/fs/container/${this.containerId}/object?uploadId=${file.uploadId}&uploadPartNum=${chunk.offset}`,
        headers: (file) => ({ Authorization: `Bearer ${file.accessToken}` }),
        forceChunkSize: true,
        chunkSize: 10 * 1024 * 1024,
        testChunks: false,
        uploadMethod: 'PUT',
        method: 'octet',
        maxChunkRetries: 10,
        permanentErrors: [404, 415, 501],
        chunkRetryInterval: 1000,
        simultaneousUploads: 10,
        readFileFn: (fileObj, fileType, startByte, endByte, chunk) => {
          const self = this;
          const resolver = () => new Promise((resolve) => {
            const bytes = fileObj.file[fileObj.uploader.constructor.sliceName](startByte, endByte, fileType);
            const reader = new FileReader();
            reader.addEventListener('loadend', () => {
              if (!chunk.file.hashedChunks.has(chunk.offset)) {
                chunk.file.hashedChunks.set(chunk.offset, true);
                chunk.file.hasher.update(new Uint8Array(reader.result));
              }
              chunk.file.totalChunks = chunk.file.chunks.length;
              chunk.readFinished(bytes);
              resolve();
            });

            if (!self.chunkUploaded(chunk.file, chunk.offset)) {
              reader.readAsArrayBuffer(bytes);
            }
          });

          chunk.file.lastPromise = chunk.file.lastPromise ? chunk.file.lastPromise.then(resolver) : resolver();
        },
      },
    };
  },
  methods: {
    onFileAdded(file) {
      window.addEventListener('beforeunload', (event) => {
        event.returnValue = 'Вы уверены, что хотите отменить все загрузки и покинуть страницу?';
      });
      const self = this;
      $.ajax({
        url: this.fileAddedUrl(file),
        data: {
          filename: file.name,
          ...this.fileAddedData,
        },
        type: 'POST',
        success(data) {
          self.$emit('fileAddedSuccess');

          file.uploadId = data.upload_id;
          file.playlistId = data.id;
          file.accessToken = data.access_token;
          file.paused = true;
          file.uploader.trigger('fileProgress', file);
          // eslint-disable-next-line
          if (file._oldRetry) file.retry = file._oldRetry;
          file.hasher = new sha256.Hash();
          file.hashedChunks = new Map();
        },
        error() {
          file.error = true;
          file.uploader.trigger('fileError', file);
          // eslint-disable-next-line
          file._oldRetry = file._oldRetry || file.retry;
          file.retry = () => this.onFileAdded(file);
        },
      });
    },
    onFileError(root, file, message) {
      console.error(`Error occurred while uploading file ${file.name}. Error: ${message}`);
    },
    chunkUploaded(file, chunkNumber) {
      $.ajax({
        url: this.chunkUploadedUrl(file),
        data: {
          upload_id: file.uploadId,
          access_token: file.accessToken,
        },
        type: 'GET',
        success(data) {
          const uploadedChunks = data.map((elem) => elem.id);
          if (uploadedChunks.includes(chunkNumber)) {
            return true;
          }
          return false;
        },
        error() {
          return false;
        },
      });
    },
    onFileSuccess(root, file) {
      const self = this;

      $.ajax({
        url: this.fileSuccessUrl(file),
        data: {
          upload_id: file.uploadId,
          access_token: file.accessToken,
        },
        type: 'GET',
        success(data) {
          const uploadedChunksSize = data.length;
          console.log(`Uploaded ${uploadedChunksSize} chunks of ${file.totalChunks} of ${file.name}`);

          if (uploadedChunksSize === file.totalChunks) {
            console.log(`Success! ${file.name} is uploaded. Start to finish the upload.`);
            self.finishUpload(file);
          } else {
            file.error = true;
            file.uploader.trigger('fileError', file);
            console.log(`Not all chunks of ${file.name} were uploaded. Starting to retry.`);
            file.retry();
          }
        },
        error() {
          file.error = true;
          file.uploader.trigger('fileError', file);
        },
      });
    },
    finishUpload(file) {
      const self = this;
      $.ajax({
        url: this.finishUploadUrl(file),
        data: {
          upload_id: file.uploadId,
          access_token: file.accessToken,
          hash: file.hasher.digest().reduce((memo, i) => memo + (`0${i.toString(16)}`).slice(-2), ''),
          pipeline: file.pipeline,
        },
        type: 'PATCH',
        success(data) {
          self.$emit('finishUploadSuccess', data, file);
        },
      });
    },
  },
};
</script>

<style>
.uploader-file[status="waiting"] .uploader-file-pause {
  display: none !important;
}
</style>
