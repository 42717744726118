const copyToClipboard = (button) => {
  const id = button.data('id');
  const copyText = $(`.token-block[data-id='${id}']`).text();
  const tempInput = $('<textarea>');
  $('body').append(tempInput);
  tempInput.val(copyText).select();
  document.execCommand('copy');
  tempInput.remove();
};

$(document).on('click', 'a.copy-token-button', function copyTokenButtonClick() {
  copyToClipboard($(this));
});
