import { Controller } from '@hotwired/stimulus';

require('daterangepicker');

export default class extends Controller {
  static targets = ['datetimeRangeInput', 'startedAtInput', 'finishedAtInput'];

  static values = {
    timezoneOffset: Number,
    timezoneFormattedOffset: String,
    locale: String,
    single: Boolean,
  };

  initialize() {
    this.currentOffset = new Date().getTimezoneOffset() / -60;
    this.locales = {
      en: {
        format: 'MM/DD/YYYY HH:mm',
        firstDay: 1,
      },
      ru: {
        format: 'DD.MM.YYYY HH:mm',
        applyLabel: 'Ок',
        cancelLabel: 'Отмена',
        fromLabel: 'От',
        toLabel: 'До',
        customRangeLabel: 'Произвольный',
        daysOfWeek: [
          'Вс',
          'Пн',
          'Вт',
          'Ср',
          'Чт',
          'Пт',
          'Сб',
        ],
        monthNames: [
          'Январь',
          'Февраль',
          'Март',
          'Апрель',
          'Май',
          'Июнь',
          'Июль',
          'Август',
          'Сентябрь',
          'Октябрь',
          'Ноябрь',
          'Декабрь',
        ],
        firstDay: 1,
      },
    };
  }

  connect() {
    const controller = this;

    $(controller.datetimeRangeInputTarget).daterangepicker({
      startDate: controller.serverTimezoneOffset(controller.startedAtInputTarget.value),
      endDate: controller.serverTimezoneOffset(controller.finishedAtInputTarget.value),
      buttonClasses: 'ui button',
      timePicker: true,
      timePicker24Hour: true,
      locale: controller.locales[controller.localeValue],
      singleDatePicker: controller.singleValue,
    }).on('apply.daterangepicker', (ev, picker) => {
      const timezoneFormattedOffset = controller.timezoneFormattedOffsetValue;
      const pickerStartDate = picker.startDate.format('YYYY-MM-DDTHH:mm:ss');
      const pickerEndDate = picker.endDate.format('YYYY-MM-DDTHH:mm:ss');

      controller.startedAtInputTarget.value = `${pickerStartDate}${timezoneFormattedOffset}`;
      controller.finishedAtInputTarget.value = `${pickerEndDate}${timezoneFormattedOffset}`;
    });
  }

  serverTimezoneOffset(date) {
    const timestamp = new Date(date).getTime();

    return new Date(timestamp - (3600000 * this.currentOffset) + (3600000 * this.timezoneOffsetValue));
  }
}
