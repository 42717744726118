<template>
  <file-uploader
    :container-id="containerId"
    :content-id="contentId"
    :file-added-url="fileAddedUrl"
    :file-added-data="fileAddedData"
    :chunk-uploaded-url="chunkUploadedUrl"
    :file-success-url="fileSuccessUrl"
    :finish-upload-url="finishUploadUrl"
    @fileAddedSuccess="onFileAddedSuccess"
    @finishUploadSuccess="onFinishUploadSuccess"
  />
</template>

<script>
import fileUploader from '../file_uploader/index.vue';

export default {
  components: {
    fileUploader,
  },
  props: {
    containerId: String,
    contentId: String,
  },
  data() {
    return {
      fileAddedUrl: () => '/admin/contents/additional_materials/start_upload.json',
      fileAddedData: { content_id: this.contentId },
      chunkUploadedUrl: () => '/admin/contents/additional_materials/chunks_data.json',
      fileSuccessUrl: () => '/admin/contents/additional_materials/chunks_data.json',
      finishUploadUrl: () => '/admin/contents/additional_materials/finish_upload.json',
    };
  },
  methods: {
    onFileAddedSuccess() {
      $('#additional_material_submit').attr('disabled', true);
    },
    onFinishUploadSuccess(data, file) {
      $('#additional_material_platformcraft_id').val(data.platformcraft_id);
      $('#additional_material_byte_size').val(file.size);
      $('#additional_material_url').val(data.url);
      $('#additional_material_submit').removeAttr('disabled');
    },
  },
};
</script>
