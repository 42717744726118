// TODO: not found '.ui.accordion' in html for moments table
$(document)
  .on(
    'ajax:success',
    '.js-dsml-moments-tables',
    function dsmlMomentsTablesAjaxSuccess({ detail: [, , { responseText }] }) {
      $(this).html(responseText);
      $(this).find('.ui.accordion').accordion();
    },
  )
  .on(
    'ajax:success',
    '.js-ugc-moments-tables',
    function ugcMomentsTablesAjaxSuccess({ detail: [, , { responseText }] }) {
      $(this).html(responseText);
      $(this).find('.ui.accordion').accordion();
    },
  )
  .on('simpleTab:loaded', '.js-dsml-moments-tab', (event, context) => {
    $(context).find('.ui.accordion').accordion();
  })
  .on('simpleTab:loaded', '.js-ugc-moments-tab', (event, context) => {
    $(context).find('.ui.accordion').accordion();
  });
