<template>
  <span class="grey text" v-if="!subscriptions || !subscriptions.length">{{ $t('no_data') }}</span>
  <div class="ui ordered list" v-else>
    <div class="item subscription-info" v-for="subscription in preparedSubscriptions" :key="subscription.id">
      <div class="content">
        <basic-subscription-info :subscription="subscription" :superManage="superManage" />
      </div>
    </div>
  </div>
</template>

<script>
import basicSubscriptionInfo from './basic_subscription_info/basic_subscription_info.vue';
import Subscription from '../models/subscription';

export default {
  props: {
    subscriptions: Array,
    superManage: Boolean,
  },
  components: { basicSubscriptionInfo },
  computed: {
    preparedSubscriptions() {
      return this.subscriptions.map((s) => new Subscription(s));
    },
  },
};
</script>

<style scoped>
.header > .label {
  margin-right: 1em;
}
</style>
