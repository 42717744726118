const $backgroundColorsInput = $('.js-background-color-input');
const backgroundColorsData = $backgroundColorsInput.data('background-colors');
const $mainColorInput = $('.js-main-color-input');
const $lightColorInput = $('.js-light-color-input');

$backgroundColorsInput.on('change', () => {
  const selectedColor = $backgroundColorsInput.val();
  const findedBackgroundColor = backgroundColorsData.find((el) => el.name === selectedColor);
  $mainColorInput.minicolors('value', findedBackgroundColor.variations.main.hex);
  $lightColorInput.minicolors('value', findedBackgroundColor.variations.light.hex);
});
