import showNotification from '../xz_notify_lib';

const motivationForm = $('.js-motivation-form');
const motivationType = {
  'Motivation::KeepSubscription::Program': 'programs',
  'Motivation::KeepSubscription::DiscountProgram': 'discount_programs',
};

$(document)
  .on('change', '.js-motivation-type-select select', function motivationTypeSelectChange() {
    $.get({
      url: `/admin/motivation/keep_subscription/${motivationType[$(this).val()]}/form`,
      success(data) {
        motivationForm.html(data);
      },
    });
  })
  .on('ajax:success', '.js-default-motivation', function defaultMotivationAjaxSuccess() {
    $(this).find('input[type=submit]').prop('disabled', false);

    showNotification('Настройка была успешно обновлена.');
  });
