<script>
import template from './template.slim';
import Subscription from '../../models/subscription';
import SubscriptionInfo from '../subscription_info/subscription_info.vue';

export default {
  mixins: [template],
  components: { SubscriptionInfo },
  props: {
    user: Object,
  },
  data() {
    return {
      deleted_subscriptions: [],
      can_load_deleted: true,
    };
  },
  methods: {
    load_deleted() {
      if (this.can_load_deleted) {
        this.can_load_deleted = false;
        $.ajax({
          url: '/admin/subscriptions/deleted',
          data: { user_id: this.user.id },
          complete: () => {
            this.can_load_deleted = true;
          },
          success: (subscriptions) => {
            this.deleted_subscriptions = subscriptions.map((subscription) => new Subscription(subscription));
          },
        });
      }
    },
  },
};
</script>

<style>
.deleted-subscription {
  margin-top: 20px;
}
</style>
