$.fn.isInViewport = function isInViewport() {
  if ($(this)) {
    const elementTop = $(this).offset() && $(this).offset().top;
    const elementBottom = elementTop + $(this).outerHeight();
    const viewportTop = $(window).scrollTop();
    const viewportBottom = viewportTop + $(window).height();
    return elementBottom > viewportTop && elementTop < viewportBottom;
  }
  return false;
};

$(window).on('resize scroll', () => {
  if ($('#next_page').isInViewport()) {
    $('.segment#loading').toggle();
    const link = $('#next_page')[0];
    link.click();
    link.remove();
  }
});
