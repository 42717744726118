import showNotification from '../xz_notify_lib';

$('.js-dropdown-clearable').dropdown({
  clearable: true,
});

$('.js-talents-search').dropdown({
  apiSettings: {
    url: '/admin/broad_view/programs/search?term={query}',
    cache: false,
  },
  minCharacters: 1,
});

$(document)
  .on(
    'ajax:success',
    '.js-broad-view-form',
    () => {
      showNotification('Данные успешно обновлены', { type: 'success' });
    },
  );
