const CIS_COUNTRIES = ['AM', 'AZ', 'BY', 'GE', 'KG', 'KZ', 'MD', 'TJ', 'TM', 'UZ', 'UA', 'RU'];

const setCisButtonState = (dropdown, button) => {
  const countries = dropdown.dropdown('get value');
  if (!countries) {
    return;
  }
  if (CIS_COUNTRIES.every((country) => countries.indexOf(country) !== -1)) {
    button.prop('disabled', true);
  }
  button.prop('disabled', false);
};

$('button.add-cis').each((i, el) => {
  const button = $(el);
  const dropdown = button.closest('.nested-fields').find('.dropdown.countries-select');
  setCisButtonState(dropdown, button);
});

$(document)
  .on('change', '.dropdown.countries-select', function countrySelectChange() {
    const dropdown = $(this);
    const button = dropdown.closest('.nested-fields').find('button.add-cis');
    setCisButtonState(dropdown, button);
  })
  .on('click', 'button.add-cis', function addCisClick() {
    const button = $(this);
    const dropdown = button.closest('.nested-fields').find('.dropdown.countries-select');
    const countries = dropdown.dropdown('get value') || [];
    CIS_COUNTRIES.forEach((country) => {
      if (countries.indexOf(country) === -1) {
        countries.unshift(country);
      }
    });
    dropdown.dropdown('set exactly', countries);
  });
