import showNotification from '../xz_notify_lib';

const initUploaders = (type, path, elType) => {
  const method = $('form').find('input[name="_method"]');
  const methodOriginal = method.val();
  $(`.upload-${type}[type='file']`).fileupload({
    url: `/uploaders/${path}/cache/upload`,
    paramName: 'file',
    type: 'POST',
    add(e, data) {
      method.val('post');
      data.submit();
      method.val(methodOriginal);
    },
    done(e, data) {
      let cropperImagesContainer;

      const container = $(this).closest(`.${elType}-uploader`);
      const fileUpload = container.find('.hidden-file-upload').first();
      const id = fileUpload.attr('id').replace(elType, `remove_${elType}`);
      const name = fileUpload.attr('name').replace(elType, `remove_${elType}`);
      // data._response - variable from fileupload
      // eslint-disable-next-line
      const imgSize = ` | ${data._response.result.metadata.width}x${data._response.result.metadata.height}`;
      fileUpload.val(JSON.stringify(data.result));
      const fileUrl = `${window.uploadsCachePath}${data.result.id}`;
      container.find('input._destroy').val(false);
      container.find('.seo-fields').show();
      if (elType === 'cropper') {
        container.find('img.cropper').cropper('replace', fileUrl).data('custom-image', true);
      } else {
        showNotification(fileUrl);
        let html = elType === 'image' ? `<img class='ui fluid image' src='${fileUrl}' />` : fileUrl;
        html += `<input class="remove" type="hidden" name="${name}" id="${id}">`;
        if (!(type === 'media-photo')) {
          html += '<a class="destroy" href="javascript:;"><i class="big red close icon absolute"></i></a>';
        }
        container.find(`.${elType}-holder`).html(html);
      }
      if (type === 'poster' || type === 'promo-poster' || type === 'background') {
        if (type === 'background') {
          container.find('.background-header span').html(imgSize);
          if (container.is('#for-cropper')) {
            cropperImagesContainer = $(this).closest("[data-tab$='-backgrounds']");
          }
        } else {
          container.find('.poster-header span').html(imgSize);
          cropperImagesContainer = $(this).closest("[data-tab$='-posters']");
        }
        if (cropperImagesContainer) {
          const cropperImages = cropperImagesContainer
            .find('img.cropper')
            .filter((i, el) => $(el).data('custom-image') === false);
          cropperImages.cropper('replace', fileUrl);
          cropperImages.each((i, el) => {
            const cropperContainer = $(el).closest('.cropper-uploader');
            cropperContainer.find('.cropper-header span').removeClass('red').html(imgSize);
            cropperContainer.find('.cropper-header').data('dimentions', imgSize);
          });
        }
      }
      if (elType === 'cropper') {
        container.find('.cropper-header span').removeClass('red').html(imgSize);
        container.find('.cropper-header').data('dimentions', imgSize);
      }
      if (type === 'media-photo') {
        container.trigger('photo:uploaded');
      }
    },
  });
};
const onFileUploadProgress = (container) => (e) => {
  if (e.lengthComputable) {
    container.find('.progress').progress('set percent', Math.ceil(((e.loaded || e.position) / e.total) * 100));
  }
};
const initDirectUploaders = () => {
  $(document)
    .on('change', '.upload-movie[type="file"]', function uploadMovieFileChange() {
      let filename;

      const container = $(this).closest('.file-uploader');
      const movieHoler = container.find('.movie-holder');
      const filenameField = container.find('input[name="filename"]');
      if (filenameField.val().length > 0) {
        filename = `${filenameField.val()}.${this.files[0].name.split('.').pop()}`;
        filenameField.val(filename);
      } else {
        filename = this.files[0].name;
      }
      const contentType = container.find('select[name="content_type"]').val();
      const contentTypeText = container.find('select[name="content_type"]  option:selected').text();
      container.find('.progress').progress('reset');
      $.ajax({
        url: `/video_presign?filename=${filename}`,
        success: (data) => {
          const formData = new FormData();
          formData.append('file', this.files[0]);
          const n = data.fields.name.split('/');
          n.pop();
          const name = `${n.join('/')}/${contentType}/${filename}`;
          formData.append('name', name);
          formData.append('autoencoding', data.fields.autoencoding);
          formData.append('autoplayer', data.fields.autoplayer);
          formData.append('presets', container.data('presets'));
          $.ajax({
            url: data.url,
            type: 'POST',
            data: formData,
            processData: false,
            contentType: false,
            xhr: () => {
              const myXhr = $.ajaxSettings.xhr();
              if (myXhr.upload) {
                container.find('.file-field').addClass('hide');
                container.find('.progress').removeClass('hide');
                movieHoler.text(`${contentTypeText} ${filename} ${container.data('uploading-text')}`);
                myXhr.upload.addEventListener('progress', onFileUploadProgress(container), false);
              }
              return myXhr;
            },
            success: (innerData) => {
              container.find('.progress').addClass('hide');
              container.find('.hidden-file-upload').first().val(JSON.stringify({
                id: `media/videos/${innerData.object.name}`,
                storage: 'store',
                metadata: {
                  filename: innerData.object.name,
                  size: innerData.object.size,
                },
              }));
              movieHoler.text(`${contentTypeText} ${data.object.name} ${container.data('success-text')}`);
              $.ajax({
                url: $(this).data('check-transcoding-url'),
                type: 'POST',
                data: {
                  id: data.object.id,
                  filename: data.object.name,
                  content_type: contentType,
                },
              });
              $(this).val(null);
            },
          });
        },
      });
    });
};
const removeImage = (holder) => {
  holder.find('img').hide();
  holder.find('input.remove').val(1);
};
const cropImage = (e, img, finalWidth, finalHeight) => {
  const container = img.closest('.cropper-uploader');
  const croppedCanvas = img.cropper('getCroppedCanvas');
  return img
    .cropper('getCroppedCanvas', {
      width: finalWidth,
      height: finalHeight,
      fillColor: '#fff',
    })
    .toBlob(((blob) => {
      if (blob) {
        const fileName = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
        const formData = new FormData();
        formData.append('file', blob, fileName);
        $.ajax({
          url: '/uploaders/media/images/posters/cache/upload',
          type: 'POST',
          data: formData,
          processData: false,
          contentType: false,
          success: (data) => {
            container.find('.hidden-file-upload').first().val(JSON.stringify(data));
            const id = `${img.data('width')}:${img.data('height')}${img.data('type')}`;
            return $(`.cropper-header[id='${id}'] span`)
              .addClass('red')
              .html(`${croppedCanvas.width}x${croppedCanvas.height}`)
              .prepend('<i class="red icon remove reset-crop"></i>');
          },
        });
      }
    }), 'image/jpeg');
};
const initCropper = (img) => {
  const finalWidth = img.data('width');
  const finalHeight = img.data('height');
  return img.cropper({
    aspectRatio: finalWidth / finalHeight,
    autoCrop: false,
    viewMode: 2,
    rotatable: false,
    scalable: false,
    minContainerWidth: 250,
    minContainerHeight: 300,
    minCanvasWidth: 200,
    minCanvasHeight: 300,
    minCropBoxWidth: 10,
    minCropBoxHeight: 10,
    cropend(e) {
      return cropImage(e, img, finalWidth, finalHeight);
    },
    zoom(e) {
      const cropBoxData = img.cropper('getCropBoxData');
      if (!$.isEmptyObject(cropBoxData)) {
        cropImage(e, img, finalWidth, finalHeight);
      }
    },
  });
};

initUploaders('poster', 'media/images/posters', 'image');
initUploaders('promo-poster', 'media/images/promo_posters', 'image');
initUploaders('promo-cropper', 'media/images/promo_posters', 'cropper');
initUploaders('background', 'media/images/backgrounds', 'image');
initUploaders('background-cropper', 'media/images/backgrounds', 'cropper');
initUploaders('promo-page-terms', 'promo_pages/files/terms', 'file');
initUploaders('media-video', 'media/files/videos', 'file');
initUploaders('media-photo', 'media/images/photos', 'image');
initUploaders('channel-logo', 'tv/channels/logos', 'image');
initUploaders('stream-logo', 'content/streams/logos', 'image');
initUploaders('stream-channel-logo', 'content/streams/channel_logos', 'image');

initDirectUploaders();

$(document)
  .on('cocoon:after-insert', () => {
    initUploaders('poster', 'media/images/posters', 'image');
    initUploaders('background', 'media/images/backgrounds', 'image');
    initUploaders('media-photo', 'media/images/photos', 'image');

    $('img.cropper').each((i, el) => initCropper($(el)));
  })
  .on('click', '.image-holder > .destroy', function imageHolderDestroyClick() {
    removeImage($(this).closest('.image-holder'));
    $(this).closest("[class$='-uploader']").find('input._destroy').val(true);
    $(this).closest("[class$='-uploader']").find('.seo-fields').hide();
    $(this).closest('.image-uploader').find('.background-header span').empty();
    $(this).closest('.image-uploader').find('.poster-header span').empty();
    $(this).closest('.cropper-uploader').find('.cropper-header span').empty();
  })
  .on('click', '.cropper-header span i.reset-crop', function cropperResetClick() {
    const header = $(this).closest('.cropper-header');
    header.find('span').removeClass('red').html(header.data('dimentions'));
    const id = header.attr('id');
    const image = $(`img.cropper[id='${id}']`);
    image.cropper('reset').cropper('clear');
    image.closest('.cropper-uploader').find('.hidden-file-upload').first().val(null);
  })
  .on('tab:loaded', () => {
    initUploaders('poster', 'media/images/posters', 'image');
    initUploaders('cropper', 'media/images/posters', 'cropper');
    initUploaders('background', 'media/images/backgrounds', 'image');
    $('img.cropper').each((i, el) => initCropper($(el)));
  });

$('img.cropper').each((i, el) => initCropper($(el)));
