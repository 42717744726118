/* global SimpleMDE */

import refreshMdEditors from './refresh_md_editors';

const initMdEditor = () => {
  $('.md-editor').each((i, el) => {
    const simplemde = new SimpleMDE({
      element: el,
      toolbar: [
        'heading', '|',
        'bold', 'italic', 'strikethrough', '|',
        'quote', 'unordered-list', 'ordered-list', '|',
        'link', 'image', 'table', '|',
        'preview', 'side-by-side', 'fullscreen', '|',
        'guide',
      ],
      spellChecker: false,
      status: false,
    });
    simplemde.render();
  });
};

initMdEditor();

$(document)
  .on('tab:loaded', () => initMdEditor())
  .on('tab:visited', () => refreshMdEditors());
