<script>
import throttle from 'lodash/throttle';
import template from './template.slim';

export default {
  mixins: [template],
  props: {
    user: Object,
  },
  data() {
    return {
      paymentMethods: this.user.payment_methods,
    };
  },
  methods: {
    throttleSyncCard: throttle(function syncCard() {
      $.ajax({
        url: `/admin/users/${this.user.id}/sync_cards`,
        method: 'post',
        success: (paymentMethods) => {
          this.paymentMethods = paymentMethods;
          this.$modal.show('example');
        },
      });
    }, 30000, { trailing: false }),
  },
  filters: {
    activityIcon(object) {
      if (object.active) {
        return 'green check';
      }
      return 'red minus';
    },
  },
};
</script>
