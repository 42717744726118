import showNotification from '../xz_notify_lib';

const $reportForm = $('.js-content-partners-report');

$('.js-content-partners-report-async').click((e) => {
  e.preventDefault();
  $.post($reportForm.data('create-url'), $reportForm.serialize(), ({ message }) => {
    showNotification(message, { expire: 10000 });
  });
});
