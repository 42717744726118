<script>
import showNotification from '../components/xz_notify_lib';
import inputField from '../inputs/input_field.vue';
import template from './template.slim';

export default {
  components: { inputField },
  mixins: [template],
  props: {
    for: String,
  },
  data() {
    return {
      first_name_en: '',
      first_name_ru: '',
      last_name_en: '',
      last_name_ru: '',
      kinopoisk_id: '',
      open: false,
      errors: {},
    };
  },
  computed: {
    person() {
      return {
        first_name_en: this.first_name_en,
        first_name_ru: this.first_name_ru,
        last_name_en: this.last_name_en,
        last_name_ru: this.last_name_ru,
        kinopoisk_id: this.kinopoisk_id,
      };
    },
  },
  methods: {
    showForm() {
      this.open = true;
    },
    cleanPerson() {
      this.first_name_en = '';
      this.first_name_ru = '';
      this.last_name_en = '';
      this.last_name_ru = '';
      this.kinopoisk_id = '';
      this.open = false;
    },
    createPerson() {
      $.ajax({
        url: '/admin/dictionaries/people.json',
        data: {
          dictionaries_person: this.person,
        },
        type: 'POST',
        success: ({ id, first_name: firstName, last_name: lastName }) => {
          this.open = false;
          this.cleanPerson();
          this.$parent.$refs[this.for].addSelectedItem({
            id,
            title: { name: `${firstName} ${lastName}` },
          });
        },
        error: (xhr) => {
          showNotification(xhr.responseJSON.errors, { type: 'error' });
          this.errors = xhr.responseJSON.errors;
        },
      });
    },
  },
};
</script>

<style scoped>
button.add {
  margin-top: 2.1em;
}
</style>
