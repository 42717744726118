const reorderMomentItems = () => {
  $('#moment-playlist-items tbody').find('tr:visible').each((index, item) => {
    const container = $('.position-field', item);
    container.find('span').html(index + 1);
    container.find('input').val(index + 1);
  });
};

const initSortableMomentItems = () => {
  $('#moment-playlist-items tbody').sortable({
    opacity: 0.5,
    axis: 'y',
    handle: '.handler',
    tolerance: 'pointer',
    cursor: 'move',
    update() {
      reorderMomentItems();
    },
  });
};

$(document)
  .on('tab:loaded', () => initSortableMomentItems())
  .on('cocoon:after-insert', () => {
    reorderMomentItems();
    initSortableMomentItems();
  })
  .on('cocoon:after-remove', () => reorderMomentItems());
