<script>
import moment from 'moment';
import { Bar } from 'vue-chartjs/legacy';
import DateRangePicker from 'vue2-daterange-picker';
import template from './template.slim';

export default {
  mixins: [template],
  props: {
    subscriptions: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  components: { Bar, DateRangePicker },
  created() {
    this.loadBonuses();
  },
  data() {
    return {
      bonuses: {},
      dateRange: {
        startDate: moment().subtract(3, 'months').toDate(),
        endDate: moment().add(3, 'months').toDate(),
      },
    };
  },
  computed: {
    labels() {
      return [this.$t('timeline.bonus_period'), this.$t('timeline.trial_period'), this.$t('timeline.normal_period')];
    },
    chardData() {
      return {
        labels: this.labels,
        datasets: this.subscriptions.map((sub) => {
          return {
            label: this.$t('timeline.current_subscription'),
            backgroundColor: ['#f5c833', '#9cf31c', '#1b73a2'],
            data: [this.getBonusPeriod(sub), this.getTrialPeriod(sub), this.getNormalPeriod(sub)]
          }
        }),
      };
    },
    chartOptions() {
      return {
        maintainAspectRatio: false,
        indexAxis: 'y',
        plugins: {
          legend: {
            display: false,
          },
          title: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label(context) {
                const [startsAt, endsAt] = context.dataset.data[context.dataIndex];

                return `${startsAt.format('L')} - ${endsAt.format('L')}`;
              },
            },
          },
        },
        scales: {
          y: {
            stacked: true,
          },
          x: {
            type: 'time',
            time: {
              tooltipFormat: 'DD',
            },
            min: moment(this.dateRange.startDate),
            max: moment(this.dateRange.endDate),
          },
        },
      };
    },
  },
  methods: {
    loadBonuses() {
      this.subscriptions.forEach((subscription) => {
        $.ajax({
          url: `/admin/user_bonuses?subscription_id=${subscription.id}`,
          dataType: 'json',
          success: (bonuses) => {
            this.$set(this.bonuses, subscription.id, bonuses)
          },
        });
      })
    },
    getBonusRange(subscription) {
      const subscriptionBonuses = this.bonuses[subscription.id];
      if (!subscriptionBonuses || !subscriptionBonuses.length) {
        return null;
      }

      return moment.range(
          moment(subscriptionBonuses[0].starts_at).startOf('day'),
          moment(subscriptionBonuses[subscriptionBonuses.length - 1].ends_at).endOf('day'),
      );
    },
    getBonusPeriod(subscription) {
      const bonusRange = this.getBonusRange(subscription);

      if (!bonusRange) {
        return null;
      }

      return [bonusRange.start, bonusRange.end];
    },
    getTrialRange(subscription) {
      if (!subscription.trial_allowed) {
        return null;
      }

      return moment.range(
          subscription.started_at.clone().startOf('day'),
          subscription.started_at.clone()
              .add(subscription.trial_duration, subscription.trial_duration_unit)
              .endOf('day'),
      );
    },
    getTrialPeriod(subscription) {
      const trialRange = this.getTrialRange(subscription);
      if (!trialRange) {
        return null;
      }

      return [trialRange.start, trialRange.end];
    },
    getNormalPeriod(subscription) {
      const startsAt = subscription.started_at.clone().startOf('day');
      const endsAt = subscription.ends_at?.clone().endOf('day');
      const trialRange = this.getTrialRange(subscription);
      const bonusRange = this.getBonusRange(subscription);

      let startsToEndsAt = moment.range(startsAt, endsAt);


      if (trialRange) {
        startsToEndsAt = startsToEndsAt.subtract(trialRange);

        if (startsToEndsAt.length === 0) {
          return null;
        }

        [startsToEndsAt] = startsToEndsAt;
      }

      if (bonusRange) {
        startsToEndsAt = startsToEndsAt.subtract(bonusRange);

        if (startsToEndsAt.length === 0) {
          return null;
        }

        [startsToEndsAt] = startsToEndsAt;
      }

      return [startsToEndsAt.start, startsToEndsAt.end];
    },
    formatDate(date) {
      return moment(date).format('LL');
    },
  },
};
</script>
