import './background_colors';

const reorderItems = () => {
  $('#content-items tbody, #collection-items tbody').find('tr:visible').each((index, item) => {
    const container = $('.position-field', item);
    container.find('span').html(index + 1);
    container.find('input').val(index + 1);
  });
};

const initSortableItems = () => {
  $('#content-items tbody, #collection-items tbody').sortable({
    opacity: 0.5,
    axis: 'y',
    handle: '.handler',
    tolerance: 'pointer',
    cursor: 'move',
    update() {
      reorderItems();
    },
  });
};

initSortableItems();

$(document)
  .on('cocoon:after-insert', () => {
    reorderItems();
    initSortableItems();
  })
  .on('cocoon:after-remove', () => reorderItems());
