import consumer from './consumer';

const creation = (room) => consumer.subscriptions.create({ channel: 'MomentChannel', room }, {
  connected() {
    // Called when the subscription is ready for use on the server
  },
  disconnected() {
    // Called when the subscription has been terminated by the server
  },
  received(data) {
    if (data.source_type === 'transcode') {
      Object.keys(data.message).forEach((key) => {
        const { files } = data.message[key];

        if (files) {
          let filesHtml = '<ul>';
          const objectIds = [];
          files.forEach((file) => {
            filesHtml = `${filesHtml}<li>${file}</li>`;
            objectIds.push(file);
          });

          filesHtml += '</ul>';
          $(`#files_${key}`).empty().append(filesHtml);
        }

        const statusCompletedHtml = '<i class="icon circle green"></i><span><strong>Completed</strong></span>';
        const statusProgressHtml = '<i class="icon circle yellow"></i><span><strong>Progress</strong></span>';

        const { status } = (data.message[key]).task;
        $(`#code_${key}`).empty().text((data.message[key]).code);
        $(`#status_${key}`).empty().html(status === 'Completed' ? statusCompletedHtml : statusProgressHtml);

        if (status === 'Completed') {
          $(`#cut_moment_${key}`).removeAttr('disabled');
        }
      });
    } else if (data.source_type === 'error') {
      // error handling
    } else if (data.source_type === 'hls') {
      Object.keys(data.message).forEach((key) => {
        const hlsUrl = (data.message[key]);
        const hlsBlockHtml = `<span id="hls_url_${key}"><strong>${hlsUrl}</strong></span>`;
        $(`#hls_${key}`).empty().html(hlsBlockHtml);
      });
    } else {
      Object.keys(data.message).forEach((key) => {
        const previewUrl = data.message[key];
        const previewHtml = `<img width="100" class="ui small image" src="${previewUrl}">`;

        $(`#preview_${key}`).empty().html(previewHtml);
      });
    }
  },
});

const MomentChannel = (room) => ({
  room,
  moment_channel: creation(room),
});

export default MomentChannel;
