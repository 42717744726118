<script>
import template from './template.slim';
import SubscriptionInfo from '../subscription_info/subscription_info.vue';

export default {
  mixins: [template],
  components: { SubscriptionInfo },
  props: {
    user: Object,
    initialSubscriptions: Array,
  },
  data() {
    return {
      showInitial: false,
    };
  },
  methods: {
    toggleShowInitial() {
      this.showInitial = !this.showInitial;
    },
  },
};
</script>

<style>
.initial-subscription {
  margin-top: 20px;
}
</style>
