import showNotification from '../xz_notify_lib';

$(document).on(
  'change',
  '.serial-content-partner input[name=toggle_partner_content]',
  function serialContentPartnerInputChange() {
    const contentPartnerId = $(this).data('partner-id');
    const contentId = $(this).data('content-id');
    const data = {
      partner_content: {
        content_partner_id: contentPartnerId,
        content_id: contentId,
      },
    };
    if ($(this).prop('checked')) {
      $.ajax({
        url: `/admin/contents/serials/${contentId}/partner_contents`,
        type: 'POST',
        data,
        error(xhr) {
          showNotification(`Error: ${xhr.status}`, { type: 'error' });
        },
      });
    } else {
      $.ajax({
        url: `/admin/contents/serials/${contentId}/partner_contents`,
        type: 'DELETE',
        data,
        error(xhr) {
          showNotification(`Error: ${xhr.status}`, { type: 'error' });
        },
      });
    }
  },
);
