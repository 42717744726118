import XZNotify from 'xz-notify/dist/xz-notify.min';

export default (message, {
  type = 'info', expire = 5000, position = 'ne', closeable = true,
} = {}, trusted = false) => {
  document.body.appendChild(XZNotify.create(message, {
    type,
    expire,
    position,
    closeable,
  }, trusted));
};
