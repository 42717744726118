import showNotification from '../xz_notify_lib';

const reorderBackdrops = () => {
  $('.segment[data-tab$="-backdrops"]').each(function setSegmentPosition() {
    const items = $('.image-item:visible', this).has('img');
    items.each((index, item) => {
      const position = $(item).find('input.image-position');
      position.val(index + 1);
    });
  });
};

const initializeSortableBackdrops = () => {
  $('.segment[data-tab$="-backdrops"]').each(function setSegmentSortable() {
    const items = $('.image-item', this).has('img');
    $(this).sortable({
      opacity: 0.5,
      tolerance: 'pointer',
      cursor: 'move',
      items,
      handle: '.image-holder img',
      update() {
        reorderBackdrops();
      },
    });
  });
};

const initBackdrops = () => {
  initializeSortableBackdrops();
  reorderBackdrops();
};

const bindEvents = () => {
  $('.segment[data-tab$="-backdrops"]')
    .on('cocoon:after-remove', () => reorderBackdrops())
    .on('photo:uploaded', () => initBackdrops());
};

const rebuildBackground = (container, url, width, height) => {
  const fileUpload = container.find('.hidden-file-upload').first();
  const id = fileUpload.attr('id').replace('image', 'remove_image');
  const name = fileUpload.attr('name').replace('image', 'remove_image');
  const imgSize = ` | ${width}x${height}`;
  container.find('input._destroy').val(false);
  container.find('.image-holder').html(
    `<img class='ui fluid image' src='${url}' />
<input class="remove" type="hidden" name="${name}" id="${id}">
<a class="destroy" href="javascript:;"><i class="big red close icon absolute"></i></a>`,
  );
  container.find('.background-header span').html(imgSize);
};

function initBackdropsActionButtons(context = document.body) {
  $('a.backdrops-action-buttons', context).click(function backdropsActionButtonClick() {
    const button = $(this);
    const contentId = $(this).attr('data-content-id');
    const imageUrl = $(this).closest('.image-holder').find('img').attr('data-original-url');
    const backgroundType = $(this).attr('data-type');
    const contentType = $(this).attr('data-content-type');
    const formData = new FormData();
    if (contentId && imageUrl && backgroundType) {
      formData.append('content', contentId);
      formData.append('image', imageUrl);
      formData.append('type', backgroundType);
    }
    $.ajax({
      url: '/media/backgrounds/upload',
      type: 'POST',
      data: formData,
      processData: false,
      contentType: false,
      success(data) {
        let container;
        const message = $('html').attr('lang') === 'ru' ? 'Фон был изменен' : 'Background was changed';
        showNotification(message);
        if (contentType === 'movie' || contentType === 'serial') {
          container = button.closest('form')
            .find(`.bottom.attached.tab[data-tab='${contentType}-backgrounds'] .${backgroundType} .image-uploader`);
        }
        if (contentType === 'season' || contentType === 'episode') {
          container = button.closest(`.nested-fields.${contentType}-item`)
            .find(`.bottom.attached.tab[data-tab$='-backgrounds'] .${backgroundType} .image-uploader`);
        }
        rebuildBackground(container, data.url, data.width, data.height);
      },
      error() {
        const message = $('html').attr('lang') === 'ru' ? 'Фон не был изменен' : 'Background was not changed';
        showNotification(message, { type: 'error' });
      },
    });
  });
}

const initOrderFunctions = () => {
  initBackdrops();
  bindEvents();
};

initOrderFunctions();
initBackdropsActionButtons();

$(document)
  .on('cocoon:after-insert', initOrderFunctions)
  .on('tab:loaded', (event, tab) => {
    initOrderFunctions();
    initBackdropsActionButtons(tab);
  });
