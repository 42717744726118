const initFormsSubmitCallbacks = () => {
  $('.link_upload_form').on('submit', '#new_media_stream_manifest_link', function newLinkSubmit() {
    $(this).addClass('loading');
  });
  $('.links_file_upload_form').on(
    'submit',
    '#new_media_stream_manifest_links_file',
    function newLinkFileSubmit(event) {
      event.preventDefault();

      $(this).addClass('loading');
      $.ajax({
        type: 'POST',
        url: '/admin/media/stream_manifests/links_file_upload',
        data: new FormData(this),
        dataType: 'script',
        processData: false,
        contentType: false,
        success: () => $(this).removeClass('loading'),
        error: () => $(this).removeClass('loading'),
      });
    },
  );
};

const initGoStreamManifestDropdown = (scope = document) => {
  $(scope).find('.js-go-stream-manifest').dropdown({
    apiSettings: {
      url: '/admin/contents/search_go_stream_manifests?term={query}',
      cache: false,
    },
    minCharacters: 2,
  });
};

initFormsSubmitCallbacks();
initGoStreamManifestDropdown();

$(document)
  .on('tab:loaded', (event, loadedHtml) => {
    initGoStreamManifestDropdown(loadedHtml);
  });
