<script>
import template from './template.slim';

export default {
  mixins: [template],
  props: {
    history: Array,
  },
};
</script>

<style>
.truncate {
  width: 350px;
  word-break: break-all;
}
</style>
