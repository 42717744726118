const initDropdowns = (context = document) => {
  $('.dropdown:not(.vue)', context).dropdown();
  $('.dropdown:not(.vue).search').dropdown({ fullTextSearch: 'exact' });
  $('.moments-search', context).dropdown({
    apiSettings: {
      url: '/admin/contents/content_moments/search?term={query}',
    },
    minCharacters: 1,
  });
  $('.not-ugc-moments-search', context).dropdown({
    apiSettings: {
      url: '/admin/contents/content_moments/search?term={query}&not_ugc=true',
    },
    minCharacters: 1,
  });
  $('.moment-playlists-search', context).dropdown({
    apiSettings: {
      url: '/admin/contents/moment_playlists/search?term={query}',
    },
    minCharacters: 1,
  });
  $('.collection-content-item-search', context).dropdown({
    apiSettings: {
      url: '/admin/collections/search?term={query}&collection_type=content&availabilities=true',
    },
    minCharacters: 1,
  });
  $('.collection-collection-item-search', context).dropdown({
    apiSettings: {
      url: '/admin/collections/search?term={query}&collection_type=collection',
    },
    minCharacters: 1,
  });
  $('.file-search', context).dropdown({
    apiSettings: {
      url: '/media/files/search?term={query}',
    },
  });
  $('.js-origin-source-search', context).dropdown({
    apiSettings: {
      url: '/admin/contents/origin_sources/search?term={query}',
    },
    minCharacters: 1,
  });
  $('.content-search, .collections-search', context).dropdown({
    apiSettings: {
      url: '/admin/contents/search?term={query}',
    },
    minCharacters: 1,
  });
};
const noPhotoWarningMarker = (id = null) => {
  const icon = 'icon red warning circle';
  if (id) {
    $('.people-search')
      .find(`a[data-value='${id}']`)
      .not(`:has(i.${icon.replace(/ /g, '.')})`)
      .prepend("<i class='icon red warning circle'></i>");
  } else {
    $('option:selected[data-photo=false]').each((i, el) => {
      const optionVal = $(el).val();
      $(el)
        .closest('.people-search')
        .find(`a[data-value='${optionVal}']`)
        .not(`:has(i.${icon.replace(/ /g, '.')})`)
        .prepend(`<i class='${icon}'></i>`);
    });
  }
};

$('.title-search').autocomplete({
  source: `${document.location.pathname}/search`,
});

initDropdowns();
noPhotoWarningMarker();

$(document)
  .on('ready', () => initDropdowns())
  .on('cocoon:after-insert', (e, addedObject) => {
    initDropdowns(addedObject);
    noPhotoWarningMarker();
  })
  .on('tab:loaded', () => {
    initDropdowns();
    noPhotoWarningMarker();
  })
  .on('promoType:changed', () => initDropdowns());
