$('.js-create-translit')
  .on('click', function createTranslitClick() {
    const title = $(`[name='${$(this).data('title-field')}'`).val();
    $.ajax({
      type: 'POST',
      url: `/admin/transliterations?title=${title}`,
      success: ({ translit }) => {
        if ($(this).closest('div').find('input').length) {
          $(this).closest('div').find('input').val(translit);
        } else {
          $(this).closest('div').find('span').text(translit);
        }
      },
    });
  });
