import showNotification from '../xz_notify_lib';

const locale = $('html').attr('lang');

$('tr.partner-content-episode').hide();
if ($('input[id=dictionaries_content_partner_has_utm_tags]').prop('checked')) {
  $('.parameters-select').show();
} else {
  $('.parameters-select').hide();
}
$(document)
  .on(
    'ajax:success',
    '.js-content-partner-contents',
    function contentPartnerAjaxSuccess({ detail: [, , { responseText }] }) {
      $(this).html(responseText);
      $('tr.partner-content-episode').hide();
    },
  )
  .on('click', 'a.content-partner-descriptions-show', function contentPartnerDescriptionShowClick() {
    const $this = $(this);
    const tr = $this.closest('tr');
    const id = tr.data('id');
    $(`tr.partner-content-episode[data-id='${id}']`).show();
    tr.find('td:first').attr({
      rowspan: $(`tr.partner-content-episode[data-id="${id}"]`).length + 1,
    });
    $this.removeClass('content-partner-descriptions-show').addClass('content-partner-descriptions-hide');
    $this.find('i').removeClass('down').addClass('up');
  })
  .on('click', 'a.content-partner-descriptions-hide', function contentPartnerDescriptionHideClick() {
    const $this = $(this);
    const tr = $this.closest('tr');
    const id = tr.data('id');
    $(`tr.partner-content-episode[data-id='${id}']`).hide();
    tr.find('td:first').removeAttr('rowspan');
    $this.removeClass('content-partner-descriptions-hide').addClass('content-partner-descriptions-show');
    $this.find('i').removeClass('up').addClass('down');
  })
  .on('simpleTab:loaded', () => $('tr.partner-content-episode').hide())
  .on(
    'change',
    '.content-partner-contents-table input[name=toggle_partner_content]',
    function contentPartnerInputChange() {
      const $this = $(this);
      if ($this.prop('checked')) {
        $.ajax({
          url: `/admin/dictionaries/content_partners/${$this.data('partner')}/contents`,
          type: 'POST',
          data: {
            id: $this.data('id'),
          },
          success({ published, current_licenses: currentLicenses, playlist }) {
            if (published && currentLicenses && playlist) {
              $this.closest('td').find('.content-download-button').show();
            }
          },
        });
      } else {
        $.ajax({
          url: `/admin/dictionaries/content_partners/${$this.data('partner')}/contents/${$this.data('id')}`,
          type: 'DELETE',
          success() {
            $this.closest('td').find('.content-download-button').hide();
          },
        });
      }
    },
  )
  .on('change', '.partner-collection input[name=toggle_partner_collection]', function partnerCollectionInputChange() {
    const $this = $(this);
    const partnerId = $this.data('partner-id');
    if ($this.prop('checked')) {
      $.ajax({
        url: `/admin/dictionaries/content_partners/${partnerId}/collections`,
        type: 'POST',
        data: {
          id: $this.data('collection-id'),
        },
      });
    } else {
      $.ajax({
        url: `/admin/dictionaries/content_partners/${partnerId}/collections/${$this.data('collection-id')}`,
        type: 'DELETE',
      });
    }
  })
  .on(
    'change',
    '.content-partner-licenses input[name=toggle_partner_content]',
    function contentPartnerLicensesInputChange() {
      const $this = $(this);
      if ($this.prop('checked')) {
        $.ajax({
          url: '/admin/dictionaries/partner_contents',
          type: 'POST',
          data: {
            dictionaries_partner_content: {
              content_partner_id: $this.data('partner-id'),
              content_id: $this.data('content-id'),
            },
          },
          success(data) {
            $this.parents().eq(2).find('.license-details').show();
            $this.closest('.content-partner-license').data('partner-content-id', data.id);
          },
          error(xhr) {
            showNotification(`Error: ${xhr.status}`, { type: 'error' });
          },
        });
      }

      const resourceId = $this.closest('.content-partner-license').data('partner-content-id');
      $.ajax({
        url: `/admin/dictionaries/partner_contents/${resourceId}`,
        type: 'DELETE',
        success() {
          $this.parents().eq(2).find('.license-details').hide();
        },
        error(xhr) {
          showNotification(`Error: ${xhr.status}`, { type: 'error' });
        },
      });
    },
  )
  .on('click', 'a.update-watermark-link', function updateWatermarkLinkClick() {
    const $this = $(this);
    const watermarkEnabled = $this.data('watermark-enabled') !== false;
    const partnerDescriptionId = $this.closest('.content-partner-license').data('partner-content-id');
    $.ajax({
      url: `/admin/dictionaries/partner_contents/${partnerDescriptionId}`,
      type: 'PATCH',
      data: {
        dictionaries_partner_content: {
          watermark_content_enabled: !watermarkEnabled,
        },
      },
      success(
        { watermark_content_enabled: watermarkContentEnabled, watermark_content_status: watermarkContentStatus },
      ) {
        $this.data('watermarkEnabled', watermarkContentEnabled);
        $this.text(watermarkContentStatus);
      },
      error(xhr) {
        showNotification(`Error: ${xhr.status}`, { type: 'error' });
      },
    });
  })
  .on('click', 'a.content-partner-export-contents', function contentPartnerExportClick() {
    $.post('/admin/dictionaries/content_partners/contents/export', {
      content_partner_id: $(this).data('id'),
    }, () => {
      // eslint-disable-next-line
      const message = locale === 'ru' ? 'Началось формирование списка контента. Это может занять значительное время. Вы получите файл на email.' : 'The formation of the content list began. This may take a significant amount of time. You will receive the file by email.';
      showNotification(message);
    });
  })
  .on('click', '.add-all-content, .remove-all-content', () => {
    $('#contents')
      .find('table')
      .dimmer({ closable: false, variation: 'inverted' })
      .dimmer('show');
  })
  .on('click', 'a.content-download-link', function contentDownloadLinkClick() {
    $.post(
      `/admin/dictionaries/content_partners/${$(this).data('partner')}/contents/${$(this).data('id')}/download_link`,
      ({ url }) => {
        const message = locale === 'ru' ? `Ссылка на скачивание: ${url}` : `Download link: ${url}`;
        showNotification(message);
      },
    );
  })
  .on('click', 'a.gen-accesses-link', function genAccessesLinkClick() {
    const partner = $(this).data('partner');
    $.post(
      `/admin/dictionaries/content_partners/${partner}/generate_api_accesses/`,
      ({ access_key: accessKey, password, api_encrypt_key: apiEncryptKey }) => {
        const keyTag = $('input#dictionaries_content_partner_access_key');
        keyTag.val(accessKey);

        const passwordTag = $('input#dictionaries_content_partner_password');
        passwordTag.val(password);

        const apiEncryptKeyTag = $('input#dictionaries_content_partner_api_encrypt_key');
        apiEncryptKeyTag.val(apiEncryptKey);

        $('.partner-api-password').css('display', 'block');
      },
    );
  })
  .on('click', 'a.content-partner-licenses-modal', function contentPartnerLicensesModalClick() {
    const partnerId = $(this).data('partner-id');
    const contentId = $(this).data('content-id');
    const partnerLicenseType = $(this).data('partner-license-type');
    $('.ui.modal#content-partner-licenses').first().modal({
      observeChanges: true,
      onVisible() {
        const $modalForm = $(this).find('.modal-data');
        $.get(`/admin/legal/partner_licenses/modal?partner_id=${partnerId}&content_id=${contentId}`, (data) => {
          $modalForm.append(data);
          $(`#${partnerLicenseType}-license`).show();
          if (partnerLicenseType === 'inherit') {
            $('#license_submit_button').addClass('green');
            $('#license_submit_button').removeClass('orange');
          }
          $('#license_submit_button').addClass('orange');
          $('#license_submit_button').removeClass('green');
        });
      },
      onHide() {
        $(this).find('.modal-data').empty();
      },
    }).modal('show');
  })
  .on('click', 'a.select-license-type', function selectLicenseTypeClick() {
    const customLicense = $('#custom-license');
    const licenseSubmitButton = $('#license_submit_button');
    const inheritLicense = $('#inherit-license');
    const hiddenLicenseKind = $('#hidden_license_kind');
    if ($(this).data('type') === 'inherit') {
      customLicense.hide();
      licenseSubmitButton.addClass('green').removeClass('orange');
      inheritLicense.show();
      hiddenLicenseKind.val('inherit');
    } else {
      customLicense.hide();
      licenseSubmitButton.addClass('orange').removeClass('green');
      inheritLicense.show();
      hiddenLicenseKind.val('custom');
    }
  })
  .on('change', 'select#copy_partner_license', () => {
    const partnerId = $('#copy_partner_license').val();
    $.get(
      `/legal/content_partner_licenses?partner_id=${partnerId}`,
      (data) => data.forEach((license, index) => {
        $('a.add_fields#add_license_link')[0].click();
        setTimeout((() => {
          $('.dt-range-start').eq(index).children().eq(2)
            .val(license.date_start);
          $('.dt-range-end').eq(index).children().eq(2)
            .val(license.date_end);
        }), 1000);
      }),
    );
  })
  .on('change', 'input[id=dictionaries_content_partner_has_utm_tags]', function utmTagChange() {
    const $this = $(this);
    if ($this.prop('checked')) {
      $('.parameters-select').show();
    } else {
      $('.parameters-select').hide();
    }
  })
  .on('change', '.toggle-partner-channels', function togglePartnerChannelsChange() {
    const active = this.classList.contains('checked');
    const inputToggle = this.firstChild;
    const attributes = {
      active,
      tv_channel_id: inputToggle.value,
    };
    $.ajax({
      url: `/admin/dictionaries/content_partners/${$(inputToggle).data('partner')}/toggle_channel`,
      type: 'POST',
      data: attributes,
      success() {
        const message = locale === 'ru' ? 'Доступ к каналу обновлен' : 'Partner channel access was updated';
        showNotification(message);
      },
    });
  });
