<script>
import maskedInput from 'vue-masked-input';
import Truncate from 'vue-truncate-collapsed';
import filter from 'lodash/filter';
import reject from 'lodash/reject';
import template from './template.slim';
import Presence from '../../presence.vue';
import AddSubscription from '../add_subscription/add_subscription.vue';
import SubscriptionInfo from '../subscription_info/subscription_info.vue';
import DeletedSubscriptions from '../deleted_subscriptions/deleted_subscriptions.vue';
import InitialSubscriptions from '../initial_subscriptions/initial_subscriptions.vue';
import InvoicesAnalytics from '../invoices_analytics/invoices_analytics.vue';
import SubscriptionTimeline from '../subscription_timeline/subscription_timeline.vue';
import PaymentMethods from '../payment_methods/payment_methods.vue';
import EventBus from '../../event_bus';
import User from '../../models/user';

export default {
  mixins: [template],

  props: {
    userAttrs: Object,
    partners: Array,
    offers: Array,
    manage: Boolean,
    superManage: Boolean,
    allowRefund: Boolean,
    providersClasses: Array,
    availableDaysRefundCanceled: Number,
    domainLocales: Array,
  },

  components: {
    Presence,
    SubscriptionInfo,
    Truncate,
    maskedInput,
    AddSubscription,
    DeletedSubscriptions,
    InitialSubscriptions,
    InvoicesAnalytics,
    PaymentMethods,
    SubscriptionTimeline,
  },

  mounted() {
    this.onUserUpdate = (user) => {
      if (user.id !== this.user.id) return;
      this.user = new User(user);
      this.$forceUpdate();
    };
    EventBus.$on('user-update', this.onUserUpdate);
  },

  beforeUnmount() {
    EventBus.$off('user-update', this.onUserUpdate);
  },

  data() {
    return {
      user: new User(this.userAttrs),
      emailSaving: false,
      emailSaved: false,
      nameSaving: false,
      nameSaved: false,
      commentsEditing: false,
      commentsSaving: false,
      commentsOld: '',
      pinSaving: false,
      pinSaved: false,
      trial_durationSaving: false,
      trial_durationSaved: false,
    };
  },

  methods: {
    updateUserAttribute(attribute) {
      this.user.errors[attribute] = null;
      this[`${attribute}Saving`] = true;
      this[`${attribute}Saved`] = false;
      const attrs = {};
      attrs[attribute] = this.user[attribute];

      $.ajax({
        url: `/admin/users/${this.user.id}`,
        type: 'PATCH',
        data: { user: attrs },
        complete: () => {
          this[`${attribute}Saving`] = false;
        },
        error: (data) => {
          this.user.errors = data.responseJSON;
        },
        success: (data) => {
          this[`${attribute}Saved`] = true;
          this[`${attribute}Editing`] = false;
          this[`${attribute}Old`] = this.user[attribute];
          if (attribute === 'pin') {
            this.user.with_pin = data.with_pin;
            this.user.pin = data.pin;
          }
          EventBus.$emit('user-update', data);
        },
      });
    },

    resetCommentEdit() {
      this.user.comments = this.commentsOld;
      this.commentsEditing = false;
    },

    startCommentEdit() {
      this.commentsOld = this.user.comments;
      this.commentsEditing = true;
    },

    resetUserPin() {
      this.user.pin = '';
      this.updateUserAttribute('pin');
    },
  },

  computed: {
    pinFilled() {
      if (this.user.pin) {
        return this.user.pin >= 0;
      }
      return false;
    },
    initialSubscriptions() {
      return filter(this.user.subscriptions, { state: 'initial' });
    },
    normalSubscriptions() {
      return reject(this.user.subscriptions, { state: 'initial' });
    },
  },
};
</script>

<style scoped>
.user-attribute-changed-notification {
  animation: fadeOut 2s ease-out;
  opacity: 0;
}

.user-details {
  animation: slideDown 0.4s ease-out;
  overflow: hidden;
}

.subscription-forms {
  padding-top: 38px;
}

@keyframes slideDown {
  0% {
    max-height: 0px
  }
  100% {
    max-height: 500px
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1
  }
  100% {
    opacity: 0
  }
}
</style>
