<script>
import template from './template.slim';
import EventBus from '../../event_bus';
import basicSubscriptionInfo from '../basic_subscription_info/basic_subscription_info.vue';
import SubscriptionHistory from '../subscription_history/subscription_history.vue';
import Version from '../../models/version';
import showNotification from '../../components/xz_notify_lib';

export default {
  mixins: [template],

  props: {
    user: Object,
    subscription: Object,
    changeSubscription: Boolean,
    toggleAutoRenew: Boolean,
    superManage: Boolean,
    allowRefund: Boolean,
    availableDaysRefundCanceled: Number,
  },

  components: { basicSubscriptionInfo },

  data() {
    return {
      locale: window.locale,
      cancelFormShown: false,
      refundFormShown: false,
      refundReason: this.subscription.refund_reason,
      cancelReason: this.subscription.cancel_reason,
      refunding: false,
      canceling: false,
      deleting: false,
      fullRefund: false,
      subscriptionUpdated: false,
      subscriptionMessage: '',
    };
  },

  computed: {
    userVipplay() {
      return this.user.vipplay;
    },
    refundAvailable() {
      return this.allowRefund
          && (this.subscription.isPayture || this.subscription.isCloudpayments)
          && !this.subscription.refunded
          && this.user.havePaymentMethod
          && this.subscription.recentlyCanceled(this.availableDaysRefundCanceled);
    },
  },

  methods: {
    showHistory() {
      $.ajax({
        url: `/admin/subscriptions/${this.subscription.id}/history`,
        success: (data) => {
          const history = data.map((version) => new Version(version));
          this.$modal.show(SubscriptionHistory, { history }, { height: 'auto', scrollable: true, width: '80%' });
        },
      });
    },
    toggleForm(formName, closeFormName = null) {
      this[`${formName}FormShown`] = !this[`${formName}FormShown`];
      if (closeFormName) {
        this[`${closeFormName}FormShown`] = false;
      }
    },
    refundSubscription() {
      this.refundFormShown = false;
      this.cancelFormShown = false;
      this.refunding = true;
      this.subscriptionUpdated = false;
      this.subscriptionMessage = null;

      $.ajax({
        url: `/admin/subscriptions/${this.subscription.id}/refund`,
        type: 'PUT',
        data: { subscription: { full: this.fullRefund, refund_reason: this.refundReason } },
        complete: () => {
          this.refunding = false;
          this.refundReason = this.subscription.refund_reason;
          this.fullRefund = false;
        },
        success: (data) => {
          EventBus.$emit('user-update', data);
          this.subscriptionMessage = this.$t('subscriptions.refunded');
          this.subscriptionUpdated = true;
        },
        error: (data) => {
          showNotification(data.responseJSON.message, { type: 'error' });
        },
      });
    },
    cancelSubscription() {
      this.refundFormShown = false;
      this.cancelFormShown = false;
      this.canceling = true;
      this.subscriptionUpdated = false;
      this.subscriptionMessage = null;

      $.ajax({
        url: `/admin/subscriptions/${this.subscription.id}/cancel`,
        type: 'POST',
        data: { subscription: { cancel_reason: this.cancelReason } },
        complete: () => {
          this.canceling = false;
          this.cancelReason = this.subscription.cancel_reason;
        },
        success: (data) => {
          EventBus.$emit('user-update', data);
          if (this.userVipplay) {
            this.subscriptionMessage = this.$t('subscriptions.vipplay_canceled');
          } else {
            this.subscriptionMessage = this.$t('subscriptions.canceled');
          }
          this.subscriptionUpdated = true;
        },
        error: (data) => {
          showNotification(data.responseJSON.message, { type: 'error' });
        },
      });
    },
    restorePurchase() {
      $.post(
        `/${this.locale}/admin/subscriptions/${this.subscription.id}/restore`,
        (result) => {
          EventBus.$emit('user-update', result);
        },
      );
    },
    switchAutoRenew() {
      $.ajax({
        url: `/admin/subscriptions/${this.subscription.id}/toggle_auto_renew`,
        type: 'PATCH',
        data: { subscription: { recurrent: !this.subscription.recurrent } },
        success: (data) => {
          EventBus.$emit('user-update', data);
        },
      });
    },
    disableAutoProlong() {
      $.ajax({
        url: `/admin/subscriptions/${this.subscription.id}/disable`,
        type: 'PATCH',
        data: { subscription: { recurrent: !this.subscription.recurrent } },
        success: (data) => {
          EventBus.$emit('user-update', data);
        },
      });
    },
    deleteSubscription() {
      this.deleting = true;

      $.ajax({
        url: `/${this.locale}/admin/subscriptions/${this.subscription.id}`,
        type: 'DELETE',
        success: (data) => {
          EventBus.$emit('user-update', data);
          this.deleting = false;
        },
      });
    },
  },
};
</script>

<style scoped>
.header > .label {
  margin-right: 1em;
}

.refund-form, .cancel-form {
  margin-top: 10px;
}

.close-form {
  position: absolute;
  top: 0px;
  right: 0px;
  cursor: pointer;
}

.inline.fields {
  margin-bottom: 0px !important;
}

.subscription-message {
  animation: fadeOut 10s ease-out;
  opacity: 0;
}
</style>
