let dtFormatter;
let formatter;
let monthFirst;
let text;

const locale = $('html').attr('lang');
const today = new Date();
const monthsRu = [
  'Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь',
];
const textRu = {
  days: ['В', 'П', 'В', 'С', 'Ч', 'П', 'С'],
  months: [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December',
  ],
  monthsShort: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],
  today: 'Сегодня',
  now: 'Сейчас',
};

const dtFormatterRu = {
  datetime(date) {
    if (!date) {
      return '';
    }
    const minutes = date.getMinutes().toString();
    const hours = date.getHours().toString();
    const day = date.getDate().toString();
    const month = (date.getMonth() + 1).toString();
    const year = date.getFullYear().toString();
    // eslint-disable-next-line
    return `${day.length === 1 ? `0${day}` : day}.${month.length === 1 ? `0${month}` : month}.${year} ${hours.length === 1 ? `0${hours}` : hours}:${minutes.length === 1 ? `0${minutes}` : minutes}`;
  },
  dayHeader(date) {
    const month = monthsRu[date.getMonth()];
    const year = date.getFullYear().toString();
    return `${month} ${year}`;
  },
  hourHeader(date) {
    const day = date.getDate().toString();
    const month = (date.getMonth() + 1).toString();
    const year = date.getFullYear().toString();
    return `${day.length === 1 ? `0${day}` : day}.${month.length === 1 ? `0${month}` : month}.${year}`;
  },
  minuteHeader(date) {
    const day = date.getDate().toString();
    const month = (date.getMonth() + 1).toString();
    const year = date.getFullYear().toString();
    return `${day.length === 1 ? `0${day}` : day}.${month.length === 1 ? `0${month}` : month}.${year}`;
  },
};

const formatterRu = {
  date(date) {
    if (!date) {
      return '';
    }
    const day = date.getDate().toString();
    const month = (date.getMonth() + 1).toString();
    const year = date.getFullYear().toString();
    return `${day.length === 1 ? `0${day}` : day}.${month.length === 1 ? `0${month}` : month}.${year}`;
  },
  dayHeader(date) {
    const month = monthsRu[date.getMonth()];
    const year = date.getFullYear().toString();
    return `${month} ${year}`;
  },
};

const textEn = {};
const dtFormatterEn = {};
const formatterEn = {};

if (locale === 'ru') {
  monthFirst = false;
  text = textRu;
  dtFormatter = dtFormatterRu;
  formatter = formatterRu;
} else {
  monthFirst = true;
  text = textEn;
  dtFormatter = dtFormatterEn;
  formatter = formatterEn;
}

const datepickerPromoCode = () => $('.datepicker-promo-code').calendar({
  monthFirst,
  firstDayOfWeek: 1,
  ampm: false,
  type: 'date',
  minDate: new Date(today.getFullYear(), today.getMonth(), today.getDate()),
  text,
  formatter,
});
const datepickerContentReleaseDate = () => $('.datepicker-content-release-date').calendar({
  monthFirst,
  firstDayOfWeek: 1,
  ampm: false,
  type: 'date',
  text,
  formatter,
});
const startDtCalendar = (start, end) => start.calendar({
  type: 'datetime',
  endCalendar: end,
  monthFirst,
  firstDayOfWeek: 1,
  ampm: false,
  text,
  formatter: dtFormatter,
});
const endDtCalendar = (start, end) => end.calendar({
  type: 'datetime',
  startCalendar: start,
  monthFirst,
  firstDayOfWeek: 1,
  ampm: false,
  text,
  formatter: dtFormatter,
});
const makeCalendar = (start, end, obj) => {
  let c;
  let minDate;
  let initialDate;
  let maxDate;

  if (obj === 'start') {
    c = start;
  } else {
    c = end;
  }
  if (!((typeof c.data('start') === 'undefined') && (typeof c.data('end') === 'undefined'))) {
    const from = c.data('start').split('/');
    minDate = new Date(from[2], from[1] - 1, from[0]);
    const to = c.data('end').split('/');
    maxDate = new Date(to[2], to[1] - 1, to[0]);
    if (typeof c.data('init') === 'undefined') {
      if (obj === 'start') {
        initialDate = minDate;
      } else {
        initialDate = maxDate;
      }
    } else {
      const current = c.data('init').split('/');
      initialDate = new Date(current[2], current[1] - 1, current[0]);
    }
  } else {
    minDate = new Date(1878, 5, 14);
    maxDate = new Date();
    maxDate.setFullYear(maxDate.getFullYear() + 10);
    initialDate = new Date();
  }
  return {
    minDate,
    maxDate,
    initialDate,
  };
};
const startCalendar = (start, end) => {
  const calendar = makeCalendar(start, end, 'start');
  return start.calendar({
    type: 'date',
    endCalendar: end,
    monthFirst,
    firstDayOfWeek: 1,
    initialDate: calendar.initialDate,
    minDate: calendar.minDate,
    maxDate: calendar.maxDate,
    text,
    formatter,
  });
};
const endCalendar = (start, end) => {
  const calendar = makeCalendar(start, end, 'end');
  return end.calendar({
    type: 'date',
    startCalendar: start,
    monthFirst,
    firstDayOfWeek: 1,
    initialDate: calendar.initialDate,
    minDate: calendar.minDate,
    maxDate: calendar.maxDate,
    text,
    formatter,
  });
};
const makeMonthCalendar = () => {
  const minDate = new Date(2018, 3, 0);
  const maxDate = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
  const initialDate = new Date();
  return {
    minDate,
    maxDate,
    initialDate,
  };
};
const startMonthCalendar = (start, end) => {
  const calendar = makeMonthCalendar();
  return start.calendar({
    type: 'month',
    endCalendar: end,
    initialDate: calendar.initialDate,
    minDate: calendar.minDate,
    maxDate: calendar.maxDate,
    text,
  });
};
const endMonthCalendar = (start, end) => {
  const calendar = makeMonthCalendar();
  return end.calendar({
    type: 'month',
    startCalendar: start,
    initialDate: calendar.initialDate,
    minDate: calendar.minDate,
    maxDate: calendar.maxDate,
    text,
  });
};
const makeYearCalendar = (start, end, obj) => {
  let c;
  let minDate;
  let initialDate;
  let maxDate;

  if (obj === 'start') {
    c = start;
  } else {
    c = end;
  }
  if (!((typeof c.data('start') === 'undefined') && (typeof c.data('end') === 'undefined'))) {
    const from = c.data('start');
    minDate = new Date(from + 1, 0, 0);
    const to = c.data('end');
    maxDate = new Date(to - 1, 0, 0);
    if (obj === 'start') {
      initialDate = minDate;
    } else {
      initialDate = maxDate;
    }
  } else {
    minDate = new Date(1879, 0, 0);
    maxDate = new Date();
    maxDate.setFullYear(maxDate.getFullYear() + 10);
    initialDate = new Date();
  }
  return {
    minDate,
    maxDate,
    initialDate,
  };
};
const startYearCalendar = (start, end) => {
  const calendar = makeYearCalendar(start, end, 'start');
  return start.calendar({
    type: 'year',
    endCalendar: end,
    initialDate: calendar.initialDate,
    minDate: calendar.minDate,
    maxDate: calendar.maxDate,
  });
};
const endYearCalendar = (start, end) => {
  const calendar = makeYearCalendar(start, end, 'end');
  return end.calendar({
    type: 'year',
    startCalendar: start,
    initialDate: calendar.initialDate,
    minDate: calendar.minDate,
    maxDate: calendar.maxDate,
  });
};

datepickerPromoCode();
datepickerContentReleaseDate();

$.each($('.dt-range-start'), (i, el) => {
  startDtCalendar($(el), $(el).closest('.dt-range-input').find('.dt-range-end').first());
});
$.each($('.dt-range-end'), (i, el) => {
  endDtCalendar($(el).closest('.dt-range-input').find('.dt-range-start').first(), $(el));
});
$.each($('.range-start'), (i, el) => {
  startCalendar($(el), $(el).closest('.date-range-input').find('.range-end').first());
});
$.each($('.range-end'), (i, el) => {
  endCalendar($(el).closest('.date-range-input').find('.range-start').first(), $(el));
});
$.each($('.month-range-start'), (i, el) => {
  startMonthCalendar($(el), $(el).closest('.month-range-input').find('.month-range-end').first());
});
$.each($('.month-range-end'), (i, el) => {
  endMonthCalendar($(el).closest('.month-range-input').find('.month-range-start').first(), $(el));
});
$.each($('.year-range-start'), (i, el) => {
  startYearCalendar($(el), $(el).closest('.year-range-input').find('.year-range-end').first());
});
$.each($('.year-range-end'), (i, el) => {
  endYearCalendar($(el).closest('.year-range-input').find('.year-range-start').first(), $(el));
});
$.each($('.dt-access-before'), (i, el) => {
  startCalendar($(el), $('#promo_codes_fix_date_program_access_before'));
});

$(document)
  .on('cocoon:after-insert', (e, addedObject) => {
    const start = addedObject.find('.range-start');
    const end = addedObject.find('.range-end');
    startCalendar(start, end);
    endCalendar(start, end);

    const dtStart = addedObject.find('.dt-range-start');
    const dtEnd = addedObject.find('.dt-range-end');
    startDtCalendar(dtStart, dtEnd);
    endDtCalendar(dtStart, dtEnd);

    datepickerContentReleaseDate();
  })
  .on('tab:loaded', () => datepickerContentReleaseDate())
  .ajaxComplete(() => {
    $.each($('.dt-access-before'), (i, el) => {
      startCalendar($(el), $('#promo_codes_fix_date_program_access_before'));
    });
  })
  .on('click', 'a.content-partner-licenses-modal', () => setTimeout((() => {
    $.each($('.dt-range-start'), (i, el) => {
      startDtCalendar($(el), $(el).closest('.dt-range-input').find('.dt-range-end').first());
    });
    $.each($('.dt-range-end'), (i, el) => {
      endDtCalendar($(el).closest('.dt-range-input').find('.dt-range-start').first(), $(el));
    });
  }), 3000));
