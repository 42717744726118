import showNotification from '../xz_notify_lib';

$('.js-create-watermarked-poster')
  .on('click', (e) => {
    e.preventDefault();

    const locale = $('html').attr('lang');
    const data = {
      content_id: $('.js-create-watermarked-poster').data('content-id'),
      color: $('#color').val(),
      gravity: $('#gravity').val(),
    };
    $.ajax({
      url: '/admin/contents/images/watermarked_poster',
      type: 'POST',
      data,
      success() {
        document.location.reload();
      },
      error() {
        const message = locale === 'ru' ? 'Не удалось установить постер' : 'Failed to set poster';
        showNotification(message, { type: 'error' });
      },
    });
  });

$(document)
  .on('ajax:success', '.js-destroy-content-image', (e) => {
    $(e.currentTarget).hide();
  });
