// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import Vue from 'vue/dist/vue';
import SuiVue from 'semantic-ui-vue';
import {
  Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, TimeScale,
} from 'chart.js';
import 'chartjs-adapter-moment';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import 'chosen-js/chosen.min.css';
import '../src/filters';
import * as ActiveStorage from '@rails/activestorage';
import '../channels';
import VueI18n from 'vue-i18n';
import uploader from 'vue-simple-uploader';
import { Application } from '@hotwired/stimulus';
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers';
import moment from 'moment';
import { extendMoment } from 'moment-range';
import VModal from 'vue-js-modal';
import MomentChannel from '../channels/moment_channel';
import i18nMessages from '../src/i18n';
import multiselect from '../src/multiselect.vue';
import userBasicInfo from '../src/users/basic_info/basic_info.vue';
import mergedSubscriptionsInfo from '../src/users/merged_subscriptions_info.vue';
import personForm from '../src/persons/person_form.vue';
import additionalMaterialUploader from '../src/components/additional_material/index.vue';
import uploaders from '../src/components/uploaders/index.vue';

// Components
import '../src/components/admin_contents_moments';
import '../src/components/admin_user';
import '../src/components/ajax_destroy_record';
import '../src/components/bonus_program';
import '../src/components/caches';
import '../src/components/collections';
import '../src/components/compliment_page';
import '../src/components/content_backdrops';
import '../src/components/content_creation';
import '../src/components/content_filters';
import '../src/components/content_images';
import '../src/components/content_moment_playlists';
import '../src/components/content_moments';
import '../src/components/content_origin_sources';
import '../src/components/content_partners';
import '../src/components/content_serials';
import '../src/components/content_sync';
import '../src/components/content_tabs';
import '../src/components/create_translit';
import '../src/components/datetime_picker';
import '../src/components/default_playlist_items';
import '../src/components/file_uploaders';
import '../src/components/form_validation';
import '../src/components/home';
import '../src/components/infinite_scroll';
import '../src/components/licenses';
import '../src/components/main_page_blocks';
import '../src/components/motivation_programs';
import '../src/components/offer';
import '../src/components/platformcraft_objects';
import '../src/components/search';
import '../src/components/serials_content_partners';
import '../src/components/simplemde';
import '../src/components/sortable_items';
import '../src/components/stream_manifest';
import '../src/components/two_factor_auth';
import '../src/components/update_translit';
import '../src/components/utils';
import '../src/components/tv_program';
import '../src/components/checkbox';
import '../src/components/reports';
import '../src/components/broad_view';
import '../src/components/changes';
import '../src/components/epg';

require('@rails/ujs').start();

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, TimeScale);

global.MomentChannel = MomentChannel;

const Stimulus = Application.start();
window.Stimulus = Stimulus;
const context = require.context('../controllers', true, /\.js$/);
Stimulus.load(definitionsFromContext(context));

moment.locale('ru');
extendMoment(moment);

global.Vue = Vue;
Vue.use(SuiVue);
Vue.use(uploader);
Vue.use(VueI18n);
Vue.use(VModal);

ActiveStorage.start();

const i18n = new VueI18n({ locale: window.locale, messages: i18nMessages });

function initVueApp() {
  // eslint-disable-next-line
  new Vue({
    el: this,
    i18n,
    components: {
      multiselect, userBasicInfo, mergedSubscriptionsInfo, personForm,
    },
  });
}

const Trix = require('trix');
require('@rails/actiontext');

Trix.config.blockAttributes.default.tagName = 'p';

$('.vue-app').each(initVueApp);
$('.vue-app-ajax').each(initVueApp);

// Init additional material
$(() => {
  if ($('.vue-additional-material-app').length) {
    // eslint-disable-next-line
    new Vue({
      el: '.vue-additional-material-vue-app',
      i18n,
      components: { additionalMaterialUploader },
    });
  }
});

// Init uploaders
$(() => {
  if ($('.vue-uploaders-app').length) {
    // eslint-disable-next-line
    new Vue({
      el: '.vue-uploaders-app',
      i18n,
      components: { uploaders },
    });
  }
});

$(document)
  .on('tab:loaded', '.menu .item[data-url]', function menuItemTabLoaded() {
    $('.vue-app', $(`div.tab[data-tab="${$(this).data('tab')}"]`)).each(initVueApp);
  })
  .on('cocoon:after-insert', (e, addedObject) => {
    $('.vue-app', addedObject).each(initVueApp);
  });
$(document).ajaxComplete(() => {
  $('.vue-app-ajax').each(initVueApp);
});

$('.x-colorpicker').minicolors({
  position: 'right',
  theme: 'bootstrap',
});

$(document).on('change', '.offer_platform', () => {
  const offerPlatformText = $('.offer_platform option:selected').text();

  if (offerPlatformText === 'Apple' || offerPlatformText === 'Google') {
    $('.offer_alternative_offers').addClass('hide');
    $('.mobile-offers-required-switch').removeClass('required');
    $('.mobile-offers-required-switch .input textarea').removeAttr('required');
  } else {
    $('.offer_alternative_offers').removeClass('hide');
    $('.mobile-offers-required-switch').addClass('required');
    $('.mobile-offers-required-switch .input textarea').attr('required');
  }
});
