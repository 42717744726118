const initTabs = (context = document.body) => {
  $('.menu .item:not([data-url])', context).each((i, el) => {
    const $tab = $(el);
    $tab.tab({
      onLoad() {
        $tab.trigger('tab:visited', this);
      },
    });
  });
  $('.menu .item[data-url]:not(.simple-tab)', context).each((i, el) => {
    const $tab = $(el);
    $tab.tab({
      path: $tab.data('url'),
      auto: true,
      loadOnce: true,
      onFirstLoad() {
        initTabs(this);
        $('.ui.dropdown:not(.vue)', this).dropdown();
        $('.accordion').accordion();
        $tab.trigger('tab:loaded', this);
      },
    });
  });
  $('.menu .item[data-url].simple-tab', context).each((i, el) => {
    const $tab = $(el);
    $tab.tab({
      path: $tab.data('url'),
      auto: true,
      loadOnce: true,
      onFirstLoad() {
        initTabs(this);
        $tab.trigger('simpleTab:loaded', this);
      },
    });
  });
};

initTabs();
$(document).on('cocoon:after-insert', (e, addedObject) => initTabs(addedObject));
