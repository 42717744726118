<script>
import template from './template.slim';

export default {
  mixins: [template],
  props: {
    value: String,
    label: String,
    errors: Object,
  },
};
</script>
