const initCheckboxCallbacks = () => {
  $('.platformcraft_object_row .checkbox')
    .checkbox()
    .checkbox({
      onChecked() {
        $(`#platformcraft_object_form_${$(this).data('id')}`).children('input').prop('disabled', false);
      },
      onUnchecked() {
        $(`#platformcraft_object_form_${$(this).data('id')}`).children('input').prop('disabled', true);
      },
      onChange() {
        if ($('.platformcraft_object_selected.checked').length > 0) {
          $('.playlist_params_fields .field').removeClass('disabled');
        } else {
          $('.playlist_params_fields .field').addClass('disabled');
        }
      },
    });
};
const initSelectAllCheckbox = () => {
  $('.platformcraft_objects_select_all.checkbox')
    .checkbox()
    .checkbox({
      onChecked() {
        $('.platformcraft_object_selected').checkbox('check');
      },
      onUnchecked() {
        $('.platformcraft_object_selected').checkbox('uncheck');
      },
    });
};
const initPlaylistsFormSubmitCallbacks = () => {
  $('.simple_form.playlists_params').submit(() => $('.playlist_params_fields').addClass('loading'));
};
const askForFolderDelete = () => {
  if ($('.platformcraft_object_row').length === 0) {
    $('.destroy-platformcraft-object-modal').modal('show');
  }
};
const initStorageStats = () => {
  const statsBar = $('.storage_stats_bar').progress({
    onActive() {
      return $(this).progress('remove active');
    },
  });
  const percent = statsBar.progress('get percent');
  const barColorClass = (() => {
    switch (false) {
      case !(percent < 50):
        return 'green';
      case !(percent < 85):
        return 'orange';
      default:
        return 'red';
    }
  })();
  return statsBar.addClass(barColorClass);
};

initSelectAllCheckbox();
initCheckboxCallbacks();
initPlaylistsFormSubmitCallbacks();
askForFolderDelete();
initStorageStats();
