import Vue from 'vue/dist/vue';

Vue.filter('userTypeIcon', (val) => ({
  regular: 'male',
  tester: 'lab',
  service: 'suitcase',
}[val] || 'user'));

Vue.filter('subscriptionIcon', (subscription) => {
  if ((subscription.ends_at?.isBefore()) || subscription.canceled_at) {
    if (subscription.ends_at?.isAfter()) {
      return 'orange warning';
    } if (subscription.grace_period) {
      return 'orange hourglass half';
    }
    return 'red minus';
  }
  return 'green check';
});

Vue.filter('platformIcon', (platform) => ({
  payture: 'desktop',
  cloudpayments: 'desktop',
  apple: 'apple',
  google: 'android',
}[platform] || 'question circle'));
