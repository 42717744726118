import showNotification from '../xz_notify_lib';

$(document)
  .on('ajax:success', 'tr .js-destroy-origin-source', function destroyOriginSourceAjaxSuccess() {
    $(this).closest('tr').hide();
  })
  .on('ajax:success', 'tr .js-detach-origin-source', function detachOriginSourceAjaxSuccess() {
    $(this).closest('tr').hide();
  })
  .on(
    'ajax:success',
    'tr .js-destroy-origin-source-stream-manifest',
    function destroyOriginSourceStreamManifestAjaxSuccess() {
      $(this).closest('tr').hide();
    },
  )
  .on(
    'ajax:success',
    '.js-content-version-stream-destroy',
    function contentVersionStreamDestroy() {
      const btn = $(this);
      btn.closest('tr').find('.js-content-version-stream-status').removeClass('green check').addClass('red minus');
      btn.hide();
    },
  )
  .on(
    'ajax:success',
    'tr .js-transcode-watermark-origin-source',
    ({ detail: [{ origin_source_row: dataOriginSourceRow }] }) => {
      if (dataOriginSourceRow) {
        $('table.js-origin-source-table tbody:first').append(dataOriginSourceRow);
      }
    },
  )
  .on(
    'ajax:success',
    'tr .js-refresh-watermark-origin-source',
    function refreshWatermarkAjaxSuccess({ detail: [{ origin_source_row: dataOriginSourceRow }] }) {
      if (dataOriginSourceRow) {
        $(this).closest('tr').replaceWith(dataOriginSourceRow);
      }
    },
  )
  .on(
    'ajax:success',
    'tr .js-refresh-decompose-origin-source',
    function refreshDecomposeAjaxSuccess({ detail: [{ origin_source_rows: originSourceRows }] }) {
      if (originSourceRows) {
        $(this).closest('tr').replaceWith(originSourceRows);
      }
    },
  )
  .on(
    'ajax:success',
    'tr .js-refresh-transcode-origin-source',
    function refreshTranscodeAjaxSuccess({ detail: [{ origin_source_row: dataOriginSourceRow }] }) {
      if (dataOriginSourceRow) {
        $(this).closest('tr').replaceWith(dataOriginSourceRow);
      }
    },
  )
  .on('ajax:success', 'tr .js-decompose-origin-source', ({ detail: [{ origin_source_row: dataOriginSourceRow }] }) => {
    if (dataOriginSourceRow) {
      const originSourceTableTbody = $('table.js-origin-source-table tbody:first');
      if (originSourceTableTbody.length) {
        originSourceTableTbody.append(dataOriginSourceRow);
      } else {
        $('table.js-origin-source-table:first').append(`<tbody>${dataOriginSourceRow}</tbody>`);
      }
    }
  })
  .on(
    'ajax:success',
    'tr .js-transcode-audio-origin-source',
    ({ detail: [{ origin_source_row: dataOriginSourceRow }] }) => {
      if (dataOriginSourceRow) {
        $('table.js-origin-source-table tbody:first').append(dataOriginSourceRow);
      }
    },
  )
  .on('click', '#presets_submit_button', function presetsSubmitButtonClick() {
    const button = $(this);
    button.prop('disabled', true);
    const contentId = $(this).data('content-id');
    const originSourceId = $(this).data('origin-source-id');
    const checked = [];
    $('.ui.modal#origin-source-presets.visible input:checkbox:checked')
      .each((index, el) => { checked[index] = el.id; });
    button.closest('.ui.modal#origin-source-presets.visible').hide();
    $.ajax({
      url: `/admin/contents/origin_sources/${contentId}/transcode?origin_source_id=${originSourceId}`,
      type: 'POST',
      data: {
        preset_ids: checked,
      },
      success({ origin_source_rows: originSourceRows }) {
        $('.modal.visible').modal('toggle');
        const originSourceTableTbody = $('table.js-origin-source-table tbody:first');
        if (originSourceTableTbody.length) {
          originSourceTableTbody.append(originSourceRows);
        } else {
          $('table.js-origin-source-table:first').append(`<tbody>${originSourceRows}</tbody>`);
        }
      },
      error({ responseText }) {
        showNotification(responseText, { type: 'error' });
        button.prop('disabled', false);
      },
    });
  })
  .on('click', 'a.origin-source-presets-modal', function originSourcePresetsModalClick() {
    const originSourceId = $(this).data('origin-source-id');
    const contentId = $(this).data('content-id');
    $('.ui.modal#origin-source-presets').first().modal({
      observeChanges: true,
      onVisible() {
        const $modalForm = $(this).find('.origin-sources-modal-data');
        $.get(
          `/admin/contents/origin_sources/${contentId}/select_presets?origin_source_id=${originSourceId}`,
          (data) => $modalForm.append(data),
        );
      },
      onHide() {
        $(this).find('.origin-sources-modal-data').empty();
      },
    }).modal('show');
  })
  .on('click', 'a.origin-source-playlist-modal', function originSourcePlaylistModalClick() {
    const contentId = $(this).data('content-id');
    const streamableId = $(this).data('streamable-id');
    const streamableType = $(this).data('streamable-type');
    $('#origin-source-playlist').first().modal({
      observeChanges: true,
      onVisible() {
        const $modalForm = $(this).find('.origin-sources-playlist-modal-data');
        $.get(
          `/admin/contents/origin_sources/${contentId}/select_for_stream_manifest`,
          { streamable_id: streamableId, streamable_type: streamableType },
          (data) => $modalForm.append(data),
        );
      },
      onHide() {
        $(this).find('.origin-sources-playlist-modal-data').empty();
      },
    }).modal('show');
  })
  .on('click', '#create_stream_manifest_submit_button', function createStreamManifestButtonClick() {
    const button = $(this);
    button.prop('disabled', true);
    const contentId = $(this).data('content-id');
    const streamableId = $(this).data('streamable-id');
    const streamableType = $(this).data('streamable-type');
    const checked = [];
    $('.ui.modal#origin-source-playlist.visible input:checkbox:checked')
      .each((index, el) => { checked[index] = el.id; });
    $.ajax({
      url: `/admin/contents/origin_sources/${contentId}/create_stream_manifest`,
      type: 'POST',
      data: {
        origin_source_ids: checked,
        streamable_id: streamableId,
        streamable_type: streamableType,
      },
      success({ stream_manifest_row: streamManifestRow }) {
        if (streamableType === 'Content') {
          $(this).closest('.ui.modal#origin-source-playlist.visible').hide();
          $('.modal.visible').modal('toggle');
          const originSourceTbody = $('table.js-origin-source-stream-manifest tbody');
          if (originSourceTbody.length) {
            originSourceTbody.append(streamManifestRow);
          } else {
            $('table.js-origin-source-stream-manifest').append(`<tbody>${streamManifestRow}</tbody>`);
          }
        }
      },
      error({ responseText }) {
        showNotification(responseText, { type: 'error' });
        button.prop('disabled', false);
      },
    });
  });
