import moment from 'moment';

class Subscription {
  constructor(attributes) {
    Object.assign(this, attributes);

    this.created_at = moment(this.created_at);
    if (this.canceled_at) this.canceled_at = moment(this.canceled_at);
    if (this.ends_at) this.ends_at = moment(this.ends_at);
    if (this.starts_at) this.starts_at = moment(this.starts_at);
    if (this.promo_code_applied_at) this.promo_code_applied_at = moment(this.promo_code_applied_at);
  }

  get started_at() {
    return this.starts_at || this.created_at;
  }

  get isPayture() {
    return this.platform === 'payture';
  }

  get isCloudpayments() {
    return this.platform === 'cloudpayments';
  }

  recentlyCanceled(duration) {
    if (!this.canceled_at) {
      return true;
    }

    return this.canceled_at.isAfter(moment().subtract(moment.duration(duration, 's')));
  }
}

export default Subscription;
