// Order is important`!!!
$('.ui.radio.checkbox').checkbox();
$('.ui.checkbox').checkbox();
$('.ui.checkbox.toggle-preset').checkbox({
  onChecked() {
    $.post($(this).data('activate-url'));
  },
  onUnchecked() {
    $.post($(this).data('deactivate-url'));
  },
});
$('.ui.checkbox.toggle-checkbox').checkbox({
  onChecked() {
    $.ajax({
      url: $(this).data('url'),
      type: 'PUT',
    });
  },
  onUnchecked() {
    $.ajax({
      url: $(this).data('url'),
      type: 'PUT',
    });
  },
});
$('.js-main-moment-checkbox').checkbox({
  onChecked() {
    const $thisCheckbox = $(this).closest('.js-main-moment-checkbox');
    $('.js-main-moment-checkbox').not($thisCheckbox).checkbox('set unchecked');
  },
  onChange() {
    $(this).closest('form').submit();
  },
});
