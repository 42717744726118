import showNotification from '../xz_notify_lib';

const closeOpenForms = (ev) => {
  const target = $(ev.target);
  const segments = $('[id$="-form-segment"]');
  if (!target.is(segments) && !(segments.find(target).length > 0)) {
    segments.hide();
  }
};

$(document)
  .on(
    'click',
    'tbody#invoices tr[data-link], tbody#invoices td[data-link]',
    function objectTableTdClick() {
      const link = $(this).data('link');
      $('.ui.modal#show-invoice').modal({
        onShow() {
          $('.ui.modal#show-invoice .content')
            .load(link, () => $('.payment_attempts .accordion').accordion())
            .show('fast');
        },
      }).modal('show');
    },
  )
  .on('click', 'body', (ev) => closeOpenForms(ev))
  .on(
    'ajax:success',
    '.js-session-tables',
    function sessionTablesAjaxSuccess({ detail: [, , { responseText }] }) {
      $(this).html(responseText);
      $(this).find('.ui.accordion').accordion();
    },
  )
  .on(
    'ajax:success',
    '.js-invoices-tables',
    function invoicesTableAjaxSuccess({ detail: [, , { responseText }] }) {
      $(this).replaceWith(responseText);
      $(this).find('.ui.accordion').accordion();
    },
  )
  .on(
    'ajax:success',
    '.js-notifications-tables',
    function notificationsTablesAjaxSuccess({ detail: [, , { responseText }] }) {
      $(this).html(responseText);
      $(this).find('.ui.accordion').accordion();
    },
  )
  .on(
    'click',
    '.js-watching-history-tab',
    () => ($('.js-watching-items-tab').trigger('click')),
  )
  .on(
    'ajax:success',
    '.js-watching-items-table',
    function watchingItemsTableAjaxSuccess({ detail: [, , { responseText }] }) {
      $(this).replaceWith(responseText);
    },
  )
  .on(
    'ajax:success',
    '.js-watching-histories-table',
    function watchingHistoriesTableAjaxSuccess({ detail: [, , { responseText }] }) {
      $(this).replaceWith(responseText);
    },
  )
  .on(
    'ajax:success',
    '.js-sber-offers-tables',
    function sberOffersTablesAjaxSuccess({ detail: [, , { responseText }] }) {
      $(this).html(responseText);
    },
  )
  .on('simpleTab:loaded', '.js-sessions-tab', (event, context) => $(context).find('.ui.accordion').accordion())
  .on('simpleTab:loaded', '.js-invoices-tab', (event, context) => $(context).find('.ui.accordion').accordion())
  .on('simpleTab:loaded', '.js-notifications-tab', (event, context) => $(context).find('.ui.accordion').accordion())
  .on('simpleTab:loaded', '.js-sber-offers-tab', (event, context) => $(context).find('.ui.accordion').accordion())
  .on('ajax:success', '.js-bonus-activate', () => document.location.reload())
  .on('ajax:error', '.js-bonus-activate', ({ detail: [, , { responseText }] }) => {
    showNotification(responseText, { type: 'error', expire: 30000 });
  });
