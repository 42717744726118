$('.chosen-select').chosen();

// toggle of trial period
$('#trial_switcher_group').data('old_offer_trial_duration', parseInt($('input[id=offer_trial_duration]').val(), 10));
if ($('#trial_switcher_group').data('old_offer_trial_duration') === 0) {
  $('#trial_fields').hide();
  $('input[id=trial_switcher_checkbox]').prop('checked', false);
} else {
  $('input[id=trial_switcher_checkbox]').prop('checked', true);
  $('#trial_fields').show();
}
// toggle of first interval period
$('#first_interval_switcher_group').data('old_first_interval', parseInt($('input[id=offer_first_interval]').val(), 10));
$('#first_interval_switcher_group')
  .data('old_first_interval_price', parseFloat($('input[id=offer_first_interval_price]').val()));
if ($('#first_interval_switcher_group').data('old_first_interval') === 0) {
  $('#first_interval_fields').hide();
  $('input[id=first_interval_switcher_checkbox]').prop('checked', false);
} else {
  $('input[id=first_interval_switcher_checkbox]').prop('checked', true);
  $('#first_interval_fields').show();
}
// toggle of grace period
$('#grace_switcher_group').data('old_offer_grace_period', parseInt($('input[id=offer_grace_period]').val(), 10));
if ($('#grace_switcher_group').data('old_offer_grace_period') === 0) {
  $('#grace_fields').hide();
  $('input[id=grace_switcher_checkbox]').prop('checked', false);
} else {
  $('input[id=grace_switcher_checkbox]').prop('checked', true);
  $('#grace_fields').show();
}

// click on toggle of trial period
$(document)
  .on('click', '#trial_switcher_group', () => {
    if ($('input[id=trial_switcher_checkbox]').prop('checked')) {
      $('input[id=offer_trial_duration]').val($('#trial_switcher_group').data('old_offer_trial_duration'));
    } else {
      $('#trial_switcher_group')
        .data('old_offer_trial_duration', parseInt($('input[id=offer_trial_duration]').val(), 10));
      $('input[id=offer_trial_duration]').val(0);
    }
    $('#trial_fields').toggle('slow');
    // click on toggle of first interval period
  })
  .on('click', '#first_interval_switcher_group', () => {
    if ($('input[id=first_interval_switcher_checkbox]').prop('checked')) {
      $('input[id=offer_first_interval]').val($('#first_interval_switcher_group').data('old_first_interval'));
    } else {
      $('#first_interval_switcher_group')
        .data('old_first_interval', parseInt($('input[id=offer_first_interval]').val(), 10));
      $('#first_interval_switcher_group')
        .data('old_first_interval_price', parseFloat($('input[id=offer_first_interval_price]').val()));
      $('input[id=offer_first_interval]').val(0);
    }
    $('#first_interval_fields').toggle('slow');
    // click on toggle of grace period
  })
  .on('click', '#grace_switcher_group', () => {
    if ($('input[id=grace_switcher_checkbox]').prop('checked')) {
      $('input[id=offer_grace_period]').val($('#grace_switcher_group').data('old_offer_grace_period'));
    } else {
      $('#grace_switcher_group').data('old_offer_grace_period', parseInt($('input[id=offer_grace_period]').val(), 10));
      $('input[id=offer_grace_period]').val(0);
    }
    $('#grace_fields').toggle('slow');
  });

$('.js-offer-history').on('click', () => $('.js.offer-history-modal').modal('show'));
