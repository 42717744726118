const initSortableTable = () => {
  $('.sortable-table tbody').sortable({
    opacity: 0.75,
    axis: 'y',
    handle: '.handler',
    tolerance: 'pointer',
    cursor: 'move',
    scroll: false,
    update() {
      const table = $(this).closest('.sortable-table');
      $.post(table.data('update-url'), $(this).sortable('serialize', { expression: /(.+)_(.+)/ }));
      // Takes into account pagionation
      let min = -1;
      table.find('td.position').each((index, item) => {
        if (item.innerText < min || min === -1) {
          min = parseInt(item.innerText, 10);
        }
      });
      // Updates position text in each td.position for each tr
      table.find('td.position').each((index, item) => $(item).html(index + min));
    },
  });
};
const initializeSortableList = () => {
  $('#items-tree ul').each((i, el) => {
    $(el).sortable({
      axis: 'y',
      handle: '.handler',
      tolerance: 'pointer',
      cursor: 'move',
      items: '> li',
      update() {
        const url = $('#items-tree').data('update-url');
        $.post(url, $(this).sortable('serialize', { expression: /(.+)_(.+)/ }));
        const ids = $(this).sortable('toArray');
        $(`#${ids.join(',#')}`).each((index, item) => $(item).find('span.position').first().html(`${index + 1}. `));
      },
    });
  });
};

initSortableTable();
initializeSortableList();

$(document)
  .on('tab:loaded', initSortableTable)
  .on('list:loaded', initializeSortableList);
