const getHighestIndex = (type, addedObject) => {
  let highest;
  const items = addedObject.closest(`#${type}-items`).find(`.${type}-item:visible input.${type}-number`);
  const values = items.map((i, el) => parseInt($(el).val(), 10) || undefined).get();
  if (values.length === 0) {
    highest = 0;
  } else {
    highest = Math.max(...values);
    if (highest <= 0) {
      highest = 0;
    }
  }
  return highest;
};

$(document)
  .on('cocoon:after-insert', (e, addedObject) => {
    addedObject.find('input.season-number').val(getHighestIndex('season', addedObject) + 1);
    addedObject.find('input.episode-number').val(getHighestIndex('episode', addedObject) + 1);
  })
  .on('click', '.hide-season', function hideSeasonClick() {
    $(this).closest('.season-item').remove();
  });
