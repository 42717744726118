<script>
import template from './template.slim';
import SberInfo from '../sber_info/sber_info.vue';

export default {
  mixins: [template],
  props: {
    subscription: Object,
    superManage: Boolean,
  },
  mounted() {
    $('.renew-warning').popup();
  },
  computed: {
    linkToOffer() {
      if (this.subscription.offer.marketing) {
        return 'marketing_offers/';
      }
      return 'offers/';
    },
  },
  methods: {
    show_notification() {
      $.ajax({
        url: `/admin/subscriptions/${this.subscription.id}/sber_info`,
        success: (data) => {
          this.$modal.show(SberInfo, { content: data }, { height: 'auto', scrollable: true, width: '80%' });
        },
      });
    },
  },
};
</script>

<style scoped>
.header > .label {
  margin-right: 1em;
}
</style>
