<script>
import template from './template.slim';

export default {
  mixins: [template],
  props: {
    content: String,
  },
};
</script>
