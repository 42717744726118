<template>
  <div class="field select optional" :class="{ required: this.required }" v-on-clickaway="closeList">
    <label :for="id" class="select optional" @click="toggleList">{{ label }}</label>
    <input type="hidden" :name="name + '[]'" value="" v-if="selectedCollection.length == 0" />
    <input type="hidden" :name="name + '[]'" :value="item.id" v-for="item in selectedCollection" :key="item.id" />
    <div
        @click="toggleList"
        class='select optional ui fluid selection multiple search dropdown vue upward'
        :class="{ active: this.listShown }"
    >
      <draggable :list="selectedCollection" :component-data="{'click': 'toggleList'}">
        <p v-for="item in selectedCollection" :key="item.id" class="ui label transition" :class="(hiddenElementsIds != null && hiddenElementsIds.includes(item.id)) ? 'hidden' : 'visible'" @click.stop>
          <slot :id="item.id" :title="item.title">
            {{ item.title }}
            <a target="_blank" :href="`/admin/offers/${item.id}/edit`" v-if="showLinkToOffer">Перейти</a>
          </slot>
          <i class="delete icon" @click="removeSelectedItem(item.id)"></i>
        </p>
      </draggable>
      <input
          v-model='term'
          class="search"
          autocomplete="off"
          tabindex="0"
          @click.stop @focus='showList'
          @keypress.enter.stop.prevent='addInputItem'
      >
      <i class="dropdown icon" tabindex="0" @click.stop="toggleList">
        <div class="menu" tabindex="-1"></div>
      </i>
      <div
          class="menu transition"
          :class="{
            visible: listShown,
            hidden: !listShown,
            animating: animating,
            slide: animating,
            up: animating,
            in: animatingDirection == 'up',
            out: animatingDirection == 'down'
          }"
          tabindex="-1"
      >
        <div
            v-for="item in filteredListCollection"
            :key="item.id"
            @click.stop="addSelectedItem(item)"
            :class="{active: selectedCollection.includes(item)}"
            class="item"
        >
          <slot :id="item.id" :title="item.title">
            {{ item.title }}
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue/dist/vue';
import draggable from 'vuedraggable';
import { mixin as clickaway } from 'vue-clickaway';

export default {
  mixins: [clickaway],
  components: {
    draggable,
  },
  props: {
    label: String,
    name: String,
    collection: Array,
    selected: Array,
    collectionUrl: String,
    add: Boolean,
    hiddenElements: Array,
    required: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      id: this.name.replace(/[[\]]+/g, '_').replace(/_$/, ''),
      listShown: false,
      animating: false,
      animatingDirection: 'up',
      term: '',
      listCollection: this.collection,
      selectedCollection: this.selected.map((id) => this.collection.find((item) => item.id === id)),
      linkToOfferNames: ['main_page_block[offer_ids]', 'base_program[whitelist_offer_ids]'],
      hiddenElementsIds: this.hiddenElements
    };
  },
  computed: {
    filteredListCollection() {
      return this.listCollection.filter((item) => this.showListItem(item));
    },
    showLinkToOffer() {
      return this.linkToOfferNames.includes(this.name);
    },
  },
  watch: {
    term(val) {
      if (this.collectionUrl) {
        $.getJSON(`${this.collectionUrl}?term=${encodeURIComponent(val)}`, (data) => {
          this.listCollection = data;
        });
      }
    },
  },
  methods: {
    toggleList() {
      if (this.listShown) {
        this.closeList();
      } else {
        this.showList();
      }
    },
    removeSelectedItem(id) {
      const item = this.selectedCollection.find((selectedItem) => selectedItem.id === id);
      Vue.delete(this.selectedCollection, this.selectedCollection.indexOf(item));
    },
    addSelectedItem(item) {
      this.selectedCollection.push(item);
    },
    closeList() {
      if (this.listShown) {
        setTimeout(() => { this.listShown = !this.listShown; }, 300);
        this.animatingDirection = 'down';
        this.animateList();
      }
    },
    showList() {
      if (!this.listShown) {
        this.listShown = !this.listShown;
        this.animatingDirection = 'up';
        this.animateList();
      }
    },
    animateList() {
      this.animating = true;
      setTimeout(() => {
        this.animating = false;
        this.animatingDirection = null;
      }, 300);
    },
    showListItem(item) {
      return !this.selectedCollection.find((selectedItem) => selectedItem.id === item.id)
        && (this.collectionUrl || item.title.toLowerCase().indexOf(this.term.toLowerCase()) !== -1);
    },
    addInputItem() {
      if (this.add) {
        this.selectedCollection.push({ id: this.term, title: this.term });
        this.listCollection.push({ id: this.term, title: this.term });
        this.term = '';
      }
    },
  },
};
</script>

<style scoped>
.ui.multiple.dropdown .label {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: inline-block;
  vertical-align: top;
  white-space: normal;
  font-size: 1em;
  padding: 0.35714286em 0.78571429em;
  margin: 0.14285714rem 0.28571429rem 0.14285714rem 0em;
  box-shadow: 0px 0px 0px 1px rgba(34, 36, 38, 0.15) inset;
}

.ui.multiple.search.dropdown > input.search {
  width: 100%
}
</style>
