<script>
import moment from 'moment';
import filter from 'lodash/filter';
import sumBy from 'lodash/sumBy';
import groupBy from 'lodash/groupBy';
import { Bar } from 'vue-chartjs/legacy';
import DateRangePicker from 'vue2-daterange-picker';
import template from './template.slim';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';

export default {
  mixins: [template],
  props: {
    user: Object,
  },
  components: { Bar, DateRangePicker },
  data() {
    return {
      invoices: [],
      dateRange: {
        startDate: moment().subtract(6, 'months').startOf('month').toDate(),
        endDate: moment().endOf('month').toDate(),
      },
    };
  },
  created() {
    this.loadInvoices();
  },
  computed: {
    paidInvoices() {
      return filter(this.invoices, 'paid_at');
    },
    filteredInvoices() {
      return filter(
        this.paidInvoices,
        (invoice) => moment(invoice.paid_at).isBetween(this.dateRange.startDate, this.dateRange.endDate, 'month', '[]'),
      );
    },
    paidInvoicesSum() {
      return sumBy(this.filteredInvoices, 'price_cents') / 100;
    },
    groupedInvoicesByMonth() {
      return groupBy(this.filteredInvoices, (invoice) => moment(invoice.paid_at).format('MM.YYYY'));
    },
    chartData() {
      const range = moment.range(this.dateRange.startDate, this.dateRange.endDate);
      const months = Array.from(range.by('month')).map((month) => month.format('MM.YYYY'));
      return {
        labels: months,
        datasets: [{
          label: 'Сумма платежей по месяцам',
          data: months.map((date) => sumBy(this.groupedInvoicesByMonth[date], 'price_cents') / 100),
        }],
      };
    },
    chartOptions() {
      return {
        maintainAspectRatio: false,
      };
    },
  },
  methods: {
    loadInvoices() {
      $.ajax({
        url: `/admin/users/${this.user.id}/invoices`,
        dataType: 'json',
        complete: () => {
        },
        success: ({ invoices }) => {
          this.invoices = invoices;
        },
      });
    },
    formatDate(date) {
      return moment(date).format('LL');
    },
  },
};
</script>
