<template>
  <sui-table-row class="uploader-file" :status="status">
    <sui-table-cell>
      {{ file.name }}
    </sui-table-cell>
    <sui-table-cell>
      {{ formatedSize }}
    </sui-table-cell>
    <sui-table-cell class='progress' width='four'>
      <div class="uploader-file-progress" :class="progressingClass" :style="progressStyle"></div>
      <div class='content'>
        <span v-show="status !== 'uploading'">{{statusText}}</span>
        <span v-show="status === 'uploading'">
          <span>{{progressStyle.progress}}</span>
          <em>{{formatedAverageSpeed}}</em>
          <i>{{formatedTimeRemaining}}</i>
        </span>
      </div>
    </sui-table-cell>
    <sui-table-cell v-if="showPipeline">
      <!-- eslint-disable vue/no-mutating-props -->
      <sui-dropdown
          v-model='file.pipeline'
          placeholder="Please select pipeline"
          :selection="true"
          :options='pipelineOptions'
      />
      <!-- eslint-enable vue/no-mutating-props -->
    </sui-table-cell>
    <sui-table-cell v-if="status !== 'success'" width='two'>
      <sui-button-group>
        <sui-button icon="pause" @click="pause" v-if="status === 'uploading'" />
        <sui-button icon="play" @click="resume" v-if="status === 'paused'" />
        <sui-button icon="times" @click="remove" v-if="status !== 'success'" />
        <sui-button icon="redo" @click="retry" v-if="status === 'error'" />
      </sui-button-group>
    </sui-table-cell>
  </sui-table-row>
</template>

<script>
import Uploader from 'simple-uploader.js';
import events from 'vue-simple-uploader/src/common/file-events';
import secondsToStr from './utils';

const COMPONENT_NAME = 'uploader-file';

export default {
  name: COMPONENT_NAME,
  props: {
    file: {
      type: Object,
      default() {
        return {};
      },
    },
    list: {
      type: Boolean,
      default: false,
    },
    showPipeline: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      paused: false,
      error: false,
      averageSpeed: 0,
      currentSpeed: 0,
      isComplete: false,
      isUploading: false,
      size: 0,
      formatedSize: '',
      uploadedSize: 0,
      progress: 0,
      timeRemaining: 0,
      type: '',
      progressingClass: '',
    };
  },
  computed: {
    progressStyle() {
      return { width: `${Math.floor(this.progress * 100)}%` };
    },
    formatedAverageSpeed() {
      return `${Uploader.utils.formatSize(this.averageSpeed)} / s`;
    },
    status() {
      if (this.isComplete) {
        return 'success';
      } if (this.error) {
        return 'error';
      } if (this.isUploading) {
        return 'uploading';
      } if (this.paused) {
        return 'paused';
      }
      return 'waiting';
    },
    statusText() {
      const { status } = this;
      return this.file.uploader.fileStatusText[status] || status;
    },
    formatedTimeRemaining() {
      const { timeRemaining } = this;
      const { file } = this;
      if (timeRemaining === Number.POSITIVE_INFINITY || timeRemaining === 0) {
        return '';
      }
      let parsedTimeRemaining = secondsToStr(timeRemaining);
      const { parseTimeRemaining } = file.uploader.opts;
      if (parseTimeRemaining) {
        parsedTimeRemaining = parseTimeRemaining(timeRemaining, parsedTimeRemaining);
      }
      return parsedTimeRemaining;
    },
    pipelineOptions() {
      if (window.currentAdmin.roles.includes('admin')) {
        return [{ text: 'Manual', value: 'ManualUploader' }, { text: 'Partner', value: 'PartnerUploader' }];
      }
      return [{ text: 'Partner', value: 'PartnerUploader' }];
    },
  },
  watch: {
    status(newStatus, oldStatus) {
      if (oldStatus && newStatus === 'uploading' && oldStatus !== 'uploading') {
        this.tid = setTimeout(() => {
          this.progressingClass = 'uploader-file-progressing';
        }, 200);
      } else {
        clearTimeout(this.tid);
        this.progressingClass = '';
      }
    },
  },
  methods: {
    actionCheck() {
      this.paused = this.file.paused;
      this.error = this.file.error;
      this.isUploading = this.file.isUploading();
    },
    pause() {
      this.file.pause();
      this.actionCheck();
      this.fileProgress();
    },
    resume() {
      this.file.resume();
      this.actionCheck();
    },
    remove() {
      if (this.file.playlistId) {
        $.ajax({
          url: `/admin/uploaders/${this.file.playlistId}`,
          type: 'DELETE',
          data: {
            access_token: this.file.accessToken,
          },
        });
      }
      this.file.cancel();
    },
    retry() {
      this.file.retry();
      this.actionCheck();
    },
    fileEventsHandler(event, args) {
      const rootFile = args[0];
      const file = args[1];
      const target = this.list ? rootFile : file;
      if (this.file === target) {
        if (this.list && event === 'fileSuccess') {
          return;
        }
        this[`${event}`](...args);
      }
    },
    fileProgress() {
      this.progress = this.file.progress();
      this.averageSpeed = this.file.averageSpeed;
      this.currentSpeed = this.file.currentSpeed;
      this.timeRemaining = this.file.timeRemaining();
      this.uploadedSize = this.file.sizeUploaded();
      this.actionCheck();
    },
    fileSuccess() {
      this.fileProgress();
      this.error = false;
      this.isComplete = true;
      this.isUploading = false;
    },
    fileComplete() {
      this.fileSuccess();
    },
    fileError() {
      this.fileProgress();
      this.error = true;
      this.isComplete = false;
      this.isUploading = false;
    },
  },
  mounted() {
    const staticProps = ['paused', 'error', 'averageSpeed', 'currentSpeed'];
    const fnProps = [
      'isComplete',
      'isUploading',
      {
        key: 'size',
        fn: 'getSize',
      },
      {
        key: 'formatedSize',
        fn: 'getFormatSize',
      },
      {
        key: 'uploadedSize',
        fn: 'sizeUploaded',
      },
      'progress',
      'timeRemaining',
      {
        key: 'type',
        fn: 'getType',
      },
    ];
    staticProps.forEach((prop) => {
      this[prop] = this.file[prop];
    });
    fnProps.forEach((fnProp) => {
      if (typeof fnProp === 'string') {
        this[fnProp] = this.file[fnProp]();
      } else {
        this[fnProp.key] = this.file[fnProp.fn]();
      }
    });

    this.handlers = {};
    const { handlers } = this;
    const eventHandler = (event) => {
      handlers[event] = (...args) => {
        this.fileEventsHandler(event, args);
      };
      return handlers[event];
    };
    events.forEach((event) => {
      this.file.uploader.on(event, eventHandler(event));
    });
  },
  unmounted() {
    events.forEach((event) => {
      this.file.uploader.off(event, this.handlers[event]);
    });
    this.handlers = null;
  },
};
</script>

<style scoped>
td.progress, td.progress div.content {
  position: relative;
}
[status="error"] .uploader-file-progress {
  background: #ffe0e0;
}
.uploader-file-progress {
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 100%;
  background: #e2eeff;
}
.uploader-file-progressing {
  transition: all .4s linear;
}
</style>
