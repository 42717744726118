const send = (type) => $.ajax({
  url: '/admin/promo_codes/base_programs/form',
  method: 'get',
  dataType: 'script',
  data: { type },
});

function init() {
  $('#select_program_type').on('change', function programTypeChange() {
    send(this.value);
  });
}

if ($('#program_form').is(':empty')) {
  send('PromoCodes::Program');
}

// TODO: ajaxComplete without binding - bad practice
$(document).ajaxComplete(() => init());
