const reorderMomentItems = () => {
  $('#block-items tbody')
    .find('tr:visible')
    .each((index, item) => {
      const container = $('.position-field', item);
      container.find('span').html(index + 1);
      container.find('input').val(index + 1);
    });
};
const initSortableMomentItems = () => {
  $('#block-items tbody').sortable({
    opacity: 0.5,
    axis: 'y',
    handle: '.handler',
    tolerance: 'pointer',
    cursor: 'move',
    update() {
      reorderMomentItems();
    },
  });
};
const disableButton = (button, time) => {
  $(button).attr('disabled', true);
  setTimeout(() => $(button).removeAttr('disabled'), time);
};

$(document)
  .on('ready', () => initSortableMomentItems())
  .on('cocoon:after-insert', () => {
    reorderMomentItems();
    initSortableMomentItems();
  })
  .on('cocoon:after-remove', () => reorderMomentItems());

$('#cache_refresh_button').on('click', function cacheRefreshButtonClick() {
  disableButton(this, 30000);
});

const mainPageBlockDisplayType = $('#main_page_block_display_type');
const mainPageBlockDisplayTypeVal = mainPageBlockDisplayType.val();
if (mainPageBlockDisplayTypeVal !== 'belt') {
  $('#main_page_block_items_form').css('display', 'none');
}
if (mainPageBlockDisplayTypeVal !== 'offers_list') {
  $('#offer_ids').css('display', 'none');
}
if (
  mainPageBlockDisplayTypeVal === 'dsml_recommendations'
  || mainPageBlockDisplayTypeVal === 'dsml_tv_channel_recommendations'
  || mainPageBlockDisplayTypeVal === 'belt'
  || mainPageBlockDisplayTypeVal === 'offers_list'
) {
  $('#moment_playlist_ids').css('display', 'none');
}

mainPageBlockDisplayType.on('change', function mainPageBlockDisplayTypeChange() {
  const ref1 = this.value;
  const playlistIdsDisplay = ref1 === 'dsml_recommendations'
  || ref1 === 'dsml_tv_channel_recommendations'
  || ref1 === 'belt'
  || ref1 === 'offers_list' ? 'none' : 'block';
  $('#moment_playlist_ids').css('display', playlistIdsDisplay);

  const itemsDisplay = this.value === 'belt' ? 'block' : 'none';
  $('#main_page_block_items_form').css('display', itemsDisplay);

  const offerIdsDisplay = this.value === 'offers_list' ? 'block' : 'none';
  $('#offer_ids').css('display', offerIdsDisplay);
});
