const formInputs = $('form.content-form input[id*="content_"]:not([id$="_kinopoisk_id"], [id$="_duplicated_ids"])');
const yearInput = $('form.content-form input[id$="_year"]');

formInputs.keyup(() => {
  let condition = false;
  formInputs.each((i, el) => {
    if ($(el).val() === '') {
      condition = true;
    }
  });
  if (yearInput.length && !/^\d{4}$/.test(yearInput.val())) {
    condition = true;
  }
  $('#content-form-submit').prop('disabled', condition);
});

$(document)
  // marks a field for destroy when clearing an external item id input
  .on('change', 'input.content_external_item', (event) => {
    const targetElement = event.target;
    const destroyInputId = targetElement.id.replace(/item_id/g, '_destroy');
    const destroyInputElement = document.getElementById(destroyInputId);

    if (destroyInputElement) {
      destroyInputElement.value = targetElement.value === '';
    }
  });
