$(document.body)
  .on('submit', 'form.submittable', () => {
    $('#model_count').html('<div class="ui active mini inline loader"></div>');
    $('#contents').find('table')
      .dimmer({ closable: false, variation: 'inverted' })
      .dimmer('show');
  })
  .on('click', 'form .buttons button', function formButtonClick(e) {
    e.preventDefault();

    const buttons = $(this).closest('.buttons');
    const data = $(this).data('value');
    const field = $(this).closest('.field').find('input[type="hidden"]');
    if (buttons.hasClass('radio')) {
      if ($(this).hasClass('active')) {
        $(this).removeClass('active');
        field.val('').trigger('change');
      } else {
        buttons.find('.button').removeClass('active');
        $(this).addClass('active');
        field.val(data).trigger('change');
      }
    }
    if (buttons.hasClass('checkbox')) {
      if ($(this).hasClass('active')) {
        $(this).removeClass('active');
        field.val('0').trigger('change');
      } else {
        $(this).addClass('active');
        field.val('1').trigger('change');
      }
    }
  })
  .on('click', 'form .sortings .item', function formSortingItemClick() {
    const menu = $(this).closest('.sortings');
    const buttons = menu.find('.item');
    const orderField = menu.find('input[type="hidden"][name="search[order]"]');
    const directionField = menu.find('input[type="hidden"][name="search[direction]"]');
    if ($(this).hasClass('active')) {
      $(this).html($(this).data('title'));
      if (directionField.val() === 'asc') {
        directionField.val('desc').trigger('change');
        $(this).append('&nbsp;<i class="sort content descending icon"></i>');
      } else {
        directionField.val('asc').trigger('change');
        $(this).append('&nbsp;<i class="sort content ascending icon"></i>');
      }
    } else {
      buttons.removeClass('active');
      buttons.each((i, el) => {
        $(el).html($(el).data('title'));
      });
      $(this).addClass('active');
      orderField.val($(this).data('order'));
      directionField.val('asc').trigger('change');
      $(this).append('&nbsp;<i class="sort content ascending icon"></i>');
    }
    $(this).closest('form').submit();
  });
