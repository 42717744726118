import showNotification from '../xz_notify_lib';

$(document)
  .on('ajax:success', '.js-tv-program-imdb-sync', ({ detail: [data] }) => {
    if (data.redirect != null) {
      window.location.replace(data.redirect);
    }
  })
  .on('ajax:error', '.js-tv-program-imdb-sync', ({ detail: [data, , { status }] }) => {
    if (status === 500) {
      showNotification(data.error, { type: 'error' }, true);
    }
  });
