import refreshMdEditors from '../simplemde/refresh_md_editors';

const switchLocale = (locale) => {
  $(`.switchable-locale[data-locale!='${locale}']`).hide().addClass('hide');
  $(`.switchable-locale[data-locale='${locale}']`).show().removeClass('hide');
  $(`.switch-locale[data-locale!='${locale}']`).addClass('transparent');
  $(`.switch-locale[data-locale='${locale}']`).removeClass('transparent');
  refreshMdEditors();
};

$(document)
  .on('click', '.switch-locale', function switchLocaleClick() {
    switchLocale($(this).data('locale'));
  });
